import React from 'react';
import {
  rsaFrozenUser,
  rsaIsFullAuth,
  rsaIsPendingMfaSetup,
  rsaIsProvisionalSamlUser,
  rsaIsRequiredToAgreeToTerms,
  rsaSecuritySettingsChanged,
} from 'data';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getIsSharedPage } from '../Router.helpers';

import { useSetRedirectUrl } from './PrivateRoute.helpers';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuthenticated = useRecoilValue(rsaIsFullAuth);
  const isPendingMfaSetup = useRecoilValue(rsaIsPendingMfaSetup);
  const securitySettingsChanged = useRecoilValue(rsaSecuritySettingsChanged);
  const isRequiredToAgreeToTerms = useRecoilValue(rsaIsRequiredToAgreeToTerms);
  const isProvisionalSamlUser = useRecoilValue(rsaIsProvisionalSamlUser);
  const frozenUser = useRecoilValue(rsaFrozenUser);

  const location = useLocation();
  const isOnSharedPage = getIsSharedPage(location.pathname);

  useSetRedirectUrl();

  if (isOnSharedPage) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else if (isPendingMfaSetup && location.pathname !== '/account/settings') {
    return <Route {...rest} render={() => <Redirect to='/login' />} />;
  } else if ((!isAuthenticated && !frozenUser && !isPendingMfaSetup) || securitySettingsChanged) {
    return <Route {...rest} render={() => <Redirect to='/login' />} />;
  } else if (isRequiredToAgreeToTerms && location.pathname !== '/account/tos') {
    return <Route {...rest} render={() => <Redirect to='/account/tos' />} />;
  } else if (isProvisionalSamlUser && location.pathname !== '/account/sso/register') {
    return <Route {...rest} render={() => <Redirect to='/account/sso/register' />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
