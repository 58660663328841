import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  title: (theme: Theme) => ({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.white.main,
    textWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
    },
  }),
  logo: {
    paddingRight: 1,
  },
  'org-text': {
    fontWeight: 'bold',
    paddingRight: 1,
  },
};

export default styles;
