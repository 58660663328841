import React from 'react';
import PersistentSideBar from 'componentLibrary/PersistentSideBar';
import { rspFullScreenStationId } from 'data';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getIsPrivateRoute } from 'router/Router.helpers';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PICWorkflowFlag, { PICFeature } from '../PICWorkflowFlag/PICWorkflowFlag';

import { useSetIsPicWorkflowRequested } from './PICWorkflowController.helpers';
import styles from './PICWorkflowController.styles';
import PICWorkflowStateManager from './PICWorkflowStateManager';

interface Props {
  /**
   * Whether to render a placeholder for taking up space in the fullscreen modal
   */
  isPlaceholder?: boolean;
  children: React.ReactNode;
}

/**
 * A wrapper component that renders the Sidebar alongside the page content.
 * - Manages the state of the Sidebar.
 * - Rendered twice: once for the main app and once in a fullscreen modal.
 * - App version - The actual sidebar
 * - Fullscreen version - a placeholder to take up space in the modal
 * - The acutal sidebar will be rendered from the base of the application,
 *   This enables the sidebar to have a stacking order not related to the fullscreen modal
 *    and layer above the popoverMarker
 */
const PICWorkflowController = ({ children, isPlaceholder }: Props) => {
  const location = useLocation();

  const isPrivateRoute = getIsPrivateRoute(location.pathname);
  const isInFullScreenPlayer = useRecoilValue(rspFullScreenStationId);

  const isFullScreenPlaceholder = isInFullScreenPlayer && isPlaceholder;

  useSetIsPicWorkflowRequested();

  if (!isPrivateRoute) {
    return <>{children}</>;
  } else if (isFullScreenPlaceholder) {
    return (
      <PICWorkflowFlag>
        <PICFeature enabled>
          <Box sx={styles['pic-page-wrapper']}>
            <PersistentSideBar>
              <Box sx={styles['side-bar-content']}></Box>
            </PersistentSideBar>
            <Box sx={styles['main-content']}>{children}</Box>
          </Box>
        </PICFeature>
        <PICFeature enabled={false}>
          <>{children}</>
        </PICFeature>
      </PICWorkflowFlag>
    );
  }

  return (
    <PICWorkflowFlag>
      <PICFeature enabled>
        <Box sx={styles['pic-page-wrapper']}>
          <PersistentSideBar>
            <Box sx={styles['side-bar-content']}>
              <Typography variant='h1' className='visible-hidden'>
                PIC Workflow Sidebar
              </Typography>
              <PICWorkflowStateManager />
            </Box>
          </PersistentSideBar>
          <Box sx={styles['main-content']}>{children}</Box>
        </Box>
      </PICFeature>
      <PICFeature enabled={false}>
        <>{children}</>
      </PICFeature>
    </PICWorkflowFlag>
  );
};

export default PICWorkflowController;
