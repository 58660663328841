import React from 'react';

import {
  useExtendTaskExpiration,
  useRedirectToFullPano,
  useSetToInactiveIfExpired,
  useUnacknowledgedTitle,
} from './Active.helpers';
import TaskActions from './TaskActions';
import TaskInfo from './TaskInfo';

export const Active = () => {
  useUnacknowledgedTitle();

  useExtendTaskExpiration();

  useRedirectToFullPano();

  useSetToInactiveIfExpired();

  return (
    <>
      <TaskInfo />
      <TaskActions />
    </>
  );
};

export default Active;
