import React from 'react';
import SuspenseRipple from 'componentLibrary/SuspenseRipple';
import { rspwIsPicUserAvailableSynced } from 'data';
import useActivity from 'hooks/useActivity/useActivity';
import { useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { usePollForNewTask } from './Searching.helpers';
import styles from './Searching.styles';

export const Searching = () => {
  const setIsPicUserAvailable = useSetRecoilState(rspwIsPicUserAvailableSynced);
  const { endActivity } = useActivity();

  usePollForNewTask();

  return (
    <Box sx={styles['searching-container']}>
      <Box sx={styles['searching-text-container']}>
        <Typography textAlign='center'>Finding your new task</Typography>
        <Typography textAlign='center'>for AI, Satellite and Known Fire Detections</Typography>
      </Box>
      <SuspenseRipple isLoading sx={styles['loading-indicator']} />
      <Box>
        <Button
          variant='outlined'
          onClick={() => {
            setIsPicUserAvailable(false);
            endActivity();
          }}
          data-cy='pic-go-inactive-button'
        >
          Go Inactive
        </Button>
      </Box>
    </Box>
  );
};

export default Searching;
