import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  preloader: (theme: Theme) => ({
    alignItems: 'center',
    background: theme.palette.white.main,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    left: '0',
    position: 'fixed',
    top: '0',
    transition: 'opacity 0.3s linear',
    width: '100%',
    zIndex: 999,
  }),
};

export default styles;
