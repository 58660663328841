import React from 'react';
import {
  rstTaskCoordinates,
  rstTaskDistanceDisplay,
  rstTaskKnownFireId,
  rstTaskKnownFireName,
  rstTaskKnownFireSource,
  rstTaskSource,
} from 'data';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';

import TaskInfoItem from '../TaskInfoItem';

/**
 * Details that are specific to Known Fire Tasks
 */
export const KnownFireInfo = () => {
  const knownFireName = useRecoilValue(rstTaskKnownFireName);
  const knownFireSource = useRecoilValue(rstTaskKnownFireSource);
  const knownFireId = useRecoilValue(rstTaskKnownFireId);
  const distance = useRecoilValue(rstTaskDistanceDisplay);
  const coordinates = useRecoilValue(rstTaskCoordinates);
  const source = useRecoilValue(rstTaskSource);

  if (source !== 'known_fire') {
    return null;
  }

  return (
    <Box>
      <TaskInfoItem title='Known Fire Name' value={knownFireName} />
      <TaskInfoItem title='Known Fire Source' value={knownFireSource} />
      <TaskInfoItem title='Known Fire Id' value={knownFireId} />
      <TaskInfoItem title='Distance' value={distance.toString()} />
      <TaskInfoItem title='Coordinates' value={coordinates.toString()} />
    </Box>
  );
};

export default KnownFireInfo;
