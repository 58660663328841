import React, { useRef } from 'react';

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';

import { useDetectionImageCanvas } from '../../DetectionImage.helpers';

import styles from './DetectionImageCanvas.styles';

/**
 * @description - The canvas for the modal version of the detection image
 * This is its own component so the hook does not run until after the modal is open
 */
const DetectionImageCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useDetectionImageCanvas(canvasRef);

  return (
    <DialogContent>
      <Box
        role='img'
        aria-label='AI Detection with Detection Highlighted with Bounding Box'
        component='canvas'
        ref={canvasRef}
        sx={styles['detection-canvas']}
      />
    </DialogContent>
  );
};

export default DetectionImageCanvas;
