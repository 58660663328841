import React from 'react';
import DisclaimerTooltip from 'componentLibrary/DisclaimerTooltip';

import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import LegalInformation from '../LegalInformation/LegalInformation';

import styles from './LocationDisclaimerFooter.styles';

export interface Props {
  /** Whether the legal information and location disclaimer are displayed in one or two lines */
  variant?: 'stacked' | 'inline';
}

/**
 * Footer that includes a disclaimer about location data
 * @note used on pages that include maps or other location estimates
 */
export const LocationDisclaimerFooter = ({ variant = 'inline' }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisplayingStacked = variant === 'stacked' || isMobile;

  return (
    <Box
      component='footer'
      role='contentinfo'
      sx={[styles.footerContainer, ...(isDisplayingStacked ? [styles.stacked] : [styles.inline])] as SxProps<Theme>}
    >
      <LegalInformation />
      <Box sx={styles.locationDisclaimer} data-cy='location-disclaimer'>
        <Typography sx={styles.locationText} variant='body2'>
          All location information is estimated.
        </Typography>
        <DisclaimerTooltip />
      </Box>
    </Box>
  );
};

export default LocationDisclaimerFooter;
