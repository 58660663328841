/* eslint-disable @typescript-eslint/no-explicit-any */

import { ComponentType, lazy, LazyExoticComponent } from 'react';

interface CustomRoute {
  path: string;
  component: LazyExoticComponent<ComponentType>;
}

const PanoInvite = lazy((): any => import('pages/Invite/PanoInvite'));
const CommunityInvite = lazy((): any => import('pages/Invite/CommunityInvite'));
const MapView = lazy((): any => import('pages/MapView'));
const OpsCenterView = lazy((): any => import('pages/OpsCenterView'));
const IncidentDetail = lazy((): any => import('pages/IncidentDetail'));
const StationDetail = lazy((): any => import('pages/StationDetail'));
const UpdateIncidentStatusPage = lazy((): any => import('pages/UpdateIncidentStatus'));
const PanoList = lazy(() => import('pages/PanoList'));
const AccountSettings = lazy((): any => import('pages/Auth/Settings'));
const TermsOfService = lazy((): any => import('pages/Auth/TermsOfService'));
const SSORegister = lazy((): any => import('pages/Auth/SSORegister'));

export const PRIVATE_ROUTES: CustomRoute[] = [
  {
    path: '/invite',
    component: PanoInvite,
  },
  {
    path: '/community',
    component: CommunityInvite,
  },
  {
    path: '/incident/:id',
    component: IncidentDetail,
  },
  {
    path: '/incident/:id/edit',
    component: UpdateIncidentStatusPage,
  },
  {
    path: '/i/:token?',
    component: IncidentDetail,
  },
  {
    path: '/station/:id',
    component: StationDetail,
  },
  {
    path: '/map/stations/panos/:pageNo?',
    component: PanoList,
  },
  {
    path: '/map/:page?/:view?/:pageNo?',
    component: MapView,
  },
  {
    path: '/ocv/:page?/',
    component: OpsCenterView,
  },
  {
    path: '/',
    component: MapView,
  },
  {
    path: '/account/settings',
    component: AccountSettings,
  },
  {
    path: '/account/tos',
    component: TermsOfService,
  },
  {
    path: '/account/sso/register',
    component: SSORegister,
  },
];
