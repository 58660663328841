import React from 'react';
import { rstInferenceModel, rstInferenceModelName, rstInferenceModelVersion, rstTaskSource } from 'data';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';

import TaskInfoItem from '../TaskInfoItem';

export const CameraInfo = () => {
  const inferenceModel = useRecoilValue(rstInferenceModel);
  const inferenceModelName = useRecoilValue(rstInferenceModelName);
  const inferenceModelVersion = useRecoilValue(rstInferenceModelVersion);
  const source = useRecoilValue(rstTaskSource);

  if (source !== 'camera') {
    return null;
  }

  return (
    <Box>
      <TaskInfoItem title='Inference Model' value={inferenceModel} />
      <TaskInfoItem title='Inference Model Name' value={inferenceModelName} />
      <TaskInfoItem title='Inference Model Version' value={inferenceModelVersion} />
    </Box>
  );
};

export default CameraInfo;
