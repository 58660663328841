import { useEffect, useState } from 'react';
import { rstTask } from 'data';
import { useRecoilValue } from 'recoil';

export const useAutoDismissLogoutWarning = (closeWarningModal: () => void) => {
  const task = useRecoilValue(rstTask);
  const [previousTask, setPreviousTask] = useState(task);

  useEffect(() => {
    if (task && task !== previousTask) {
      closeWarningModal();
    }

    setPreviousTask(task);
  }, [closeWarningModal, previousTask, task]);
};
