import React, { useEffect } from 'react';
import useStations from 'hooks/useStations';
import { useLocation } from 'react-router';
import { getIsPrivateRoute, getIsSharedPage } from 'router/Router.helpers';
import { getPollingInterval } from 'utils';

import useInterval from '@use-it/interval';

const ListInterval = (): React.ReactElement => {
  const { refreshStations } = useStations();
  const location = useLocation();

  const isOnSharedPage = getIsSharedPage(location.pathname);

  useEffect(() => {
    if (getIsPrivateRoute(location.pathname) && !isOnSharedPage) {
      refreshStations();
    }
  }, [isOnSharedPage, location.pathname, refreshStations]);

  useInterval(async () => {
    if (getIsPrivateRoute(location.pathname)) {
      refreshStations(true, true);
    }
  }, getPollingInterval());

  return <></>;
};

export default ListInterval;
