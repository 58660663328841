import {
  rsmIncidentMapBounds,
  rsmWidgetMapBounds,
  rssIsStationDisabledById,
  rssIsStationOfflineById,
  rssQueryStationById,
} from 'data';
import { atom, selectorFamily } from 'recoil';
import { LonLat, Station } from 'types';

export const rsmGetIsPinInIncidentMapBounds = selectorFamily<boolean, number>({
  key: 'rsmGetIsPinInIncidentMapBounds',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const bounds = get(rsmIncidentMapBounds);

      if (!bounds) {
        return false;
      }

      return bounds.contains({ lat: station?.lat, lon: station?.lon });
    },
});

export const rsmGetIsPinInWidgetMapBounds = selectorFamily<boolean, number>({
  key: 'rsmGetIsPinInWidgetMapBounds',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const bounds = get(rsmWidgetMapBounds);

      if (!bounds) {
        return false;
      }

      return bounds.contains({ lat: station?.lat, lon: station?.lon });
    },
});

/**
 * The id of the selected Station Pin
 * - currently only used for triangulation
 */
export const rsmSelectedPinStationId = atom<Station['id']>({
  key: 'rsmSelectedPinStationId',
  default: null,
});

/**
 * Whether the provided station Id is the currently selected station for adding to the triangulation
 * - currently triangulation only
 */
export const rsmIsStationSelectedById = selectorFamily({
  key: 'rsmIsStationSelectedById',
  get:
    (stationId: Station['id']) =>
    ({ get }) => {
      const selectedStationId = get(rsmSelectedPinStationId);

      return selectedStationId === stationId;
    },
});

/**
 * Whether the the popup for the provided station id is open
 * - currently triangulation only
 */
export const rsmIsUnavailableForTriangulationPopupOpen = selectorFamily({
  key: 'rsmIsUnavailableForTriangulationPopupOpen',
  get:
    (stationId: Station['id']) =>
    ({ get }) => {
      const isStationSelected = get(rsmIsStationSelectedById(stationId));
      const isStationOffline = get(rssIsStationOfflineById(stationId));
      const isStationInactive = get(rssIsStationDisabledById(stationId));

      return (isStationOffline || isStationInactive) && isStationSelected;
    },
});

/**
 * Whether the station icon for the given id is disabled
 * - currently triangulation only
 */
export const rsmIsStationIconDisabled = selectorFamily({
  key: 'rsmIsStationIconDisabled',
  get:
    (stationId: Station['id']) =>
    ({ get }) => {
      const isStationSelected = get(rsmIsStationSelectedById(stationId));
      const isStationOffline = get(rssIsStationOfflineById(stationId));

      return isStationOffline && !isStationSelected;
    },
});

/** Whether 'drop a pin' mode is enabled to get map coordinates */
export const rsmIsDropPinModeEnabled = atom<boolean>({
  key: 'rsmIsDropPinModeEnabled',
  default: false,
});

/** The coordinates of the dropped pin when 'drop a pin' mode is enabled */
export const rsmDroppedMapPinCoordinates = atom<LonLat>({
  key: 'rsmDroppedMapPinCoordinates',
  default: null,
});
