import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  '.govIcons': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.govTopIcons': {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '2px',
  },
  '.iconTransmissionLine': {
    height: '33px',
  },
  '.iconWaterSpigot': {
    height: '22px',
    marginTop: '5px',
    marginLeft: '2px',
  },
};

export default styles;
