/**
 * Convert a hex string (with or without the #) to r, g, b values.
 *
 * Implementation from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 *
 * Note: this only works for 6 character hex codes, not 3 character shortand or 8 character with alpha
 * @param hex
 * @returns object with r, g, b values
 */
export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Convert a hex string (with or without the #) to an [r, g, b] array.
 *
 * Note: this only works for 6 character hex codes, not 3 character shortand or 8 character with alpha
 * @param hex
 * @returns array of the [r, g, b] values
 */
export function hexToRgbArray(hex: string): [number, number, number] {
  const rgb = hexToRgb(hex);
  if (!rgb) {
    return null;
  }
  const { r, g, b } = rgb;

  return [r, g, b];
}
