import React from 'react';
import iconPanoLogo from 'assets/icons/pano-logo.svg';
import iconPanoLogoWhite from 'assets/icons/pano-logo-white.svg';
import Link from 'componentLibrary/Link';
import { rsaUserOrgType, rscListScrollTop } from 'data';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { orgText } from '../Header.helpers';

import styles from './HeaderTitle.styles';
import OrgIcon from './OrgIcon';

export const HeaderTitle = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const orgType = useRecoilValue(rsaUserOrgType);
  const setListScrollTop = useSetRecoilState(rscListScrollTop);
  const isCustomOrgHeader = !!orgText[orgType];

  if (isCustomOrgHeader) {
    return (
      <>
        <Grid item xs={0} md={4}></Grid>
        <Grid item xs={8} md={4}>
          <Link
            sx={styles.title}
            to='/'
            onClick={(): void => setListScrollTop(0)}
            dataCy='home-link'
            aria-label='Pano 360 Homepage'
          >
            <Box component='img' height='28px' alt='Pano' src={iconPanoLogoWhite} sx={styles.logo} data-cy='logo-img' />
            {!isMobile && (
              <Typography component='span' variant='h6' sx={styles['org-text']}>
                {orgText[orgType]}
              </Typography>
            )}
            <Box sx={styles.orgLogo} data-cy='org-logo'>
              <OrgIcon orgType={orgType} />
            </Box>
          </Link>
        </Grid>
      </>
    );
  }

  return (
    <Grid item xs={8}>
      <Link to='/' onClick={(): void => setListScrollTop(0)} dataCy='home-link' aria-label='Pano 360 Homepage'>
        <Box component={'img'} alt='Pano logo' src={iconPanoLogo} sx={styles.logo} height='28px' data-cy='logo-img' />
      </Link>
    </Grid>
  );
};

export default HeaderTitle;
