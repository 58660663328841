import useWindowSize from 'hooks/useWindowSize';
import { Station } from 'types';
import { get180Dois, getStationImageDimension } from 'utils';

/**
 * The canvas height for small player.
 */
export default function useSmallCanvasHeight(station: Station, canvasRef: React.RefObject<HTMLCanvasElement>) {
  const windowSize = useWindowSize();
  const canvasWidth = canvasRef?.current?.offsetWidth || windowSize.width;
  const [cameraImageWidth, cameraImageHeight] = getStationImageDimension(station);

  return (canvasWidth * (1 / get180Dois(station)) * cameraImageHeight) / cameraImageWidth;
}
