import { Dispatch, SetStateAction, useEffect } from 'react';
import { rsaUser } from 'data';
import _uniq from 'lodash/uniq';
import { useRecoilValue } from 'recoil';
import { ORG_TYPES, OrgInfo } from 'types';

export const bgColors = {
  [ORG_TYPES.PANO]: 'orgPanoBg',
  [ORG_TYPES.INSURANCE]: 'orgInsuranceBg',
  [ORG_TYPES.UTILITY]: 'orgUtilityBg',
  [ORG_TYPES.OWNER]: 'orgOwnerBg',
  [ORG_TYPES.GOVERNMENT]: 'orgGovernmentBg',
};

export const orgText = {
  [ORG_TYPES.PANO]: 'for Pano Intelligence Center',
  [ORG_TYPES.INSURANCE]: 'for Insurance',
  [ORG_TYPES.UTILITY]: 'for Utilities',
  [ORG_TYPES.OWNER]: 'for Landowners',
  [ORG_TYPES.GOVERNMENT]: 'for Government',
};

/** Move to selector */
export const useSetOrgType = (setOrgType: Dispatch<SetStateAction<string>>) => {
  const user = useRecoilValue(rsaUser);
  useEffect(() => {
    if (user?.orgs) {
      const orgs: OrgInfo[] = user?.orgs;
      const uniqTypes: string[] = _uniq(orgs.map((org) => org?.type || ''));
      const tempType: string = uniqTypes.length > 1 || uniqTypes.length === 0 ? '' : uniqTypes.pop();

      // Now check if tempType is one of the types that uses a custom header
      const text: string = orgText[tempType];
      if (text) {
        setOrgType(tempType);
      } else {
        setOrgType('');
      }
    }
  }, [setOrgType, user]);
};
