import * as Sentry from '@sentry/react';

/**
 * @description A single point of abstraction for error reporting
 * @Note We create this so if we switch who we report errors to
 * we minimize the surface of the change
 * @param errorMessage - The message to report to Sentry
 */
export const reportErrorMessage = (errorMessage: string) => {
  Sentry.captureMessage(errorMessage);
};
