import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';

export default class AnalyticsApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init() {
    const api: AxiosInstance = authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance) {
    this.api = axiosInstance;
  }

  /**
   * @description Tracks a user event
   * @param eventName - The name of the event to track
   * @param parameters - The data we want to associate with the event
   */
  async apiTrackEvent({
    eventName,
    parameters,
  }: {
    eventName: string;
    parameters?: { [key: string]: unknown };
  }): Promise<void> {
    try {
      await this.api.post(
        `/userevent`,
        {
          name: eventName,
          parameters,
        },
        {
          shouldSkipErrorHandling: true,
        },
      );

      return null;
    } catch (e) {
      return null;
    }
  }
}
