import React from 'react';
import spinner from 'assets/icons/spinner.svg';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import styles from './SuspenseRipple.styles';

interface Props {
  children?: React.ReactElement;
  /** Wether the ripple is loading */
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

/**
 * Renders the Ripple loader as a placeholde until the content is loaded
 */
export default function SuspenseRipple({ children = null, isLoading = false, sx }: Props) {
  if (isLoading) {
    return (
      <Box sx={sx}>
        <Box component='img' src={spinner} alt='loader' sx={styles.loader} />
      </Box>
    );
  }

  return children;
}
