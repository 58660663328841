import React from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import styles from './Alert.styles';

export interface AlertProps {
  children?: React.ReactNode | string | null;
  type?: string;
  show?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
}

export default function Alert({
  children = null,
  type = 'success',
  show = true,
  className = '',
  sx = {},
}: AlertProps): React.ReactElement {
  if (!show) {
    return null;
  }

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx]), styles.alert, styles[type]]} className={`${className} ${type}`}>
      {children}
    </Box>
  );
}
