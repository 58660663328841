import React, { MouseEventHandler, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SxProps, Theme } from '@mui/material';
import MuiLink from '@mui/material/Link';

interface Props {
  children: ReactNode;
  className?: string;
  /**
   * To be used for mailto urls
   */
  href?: string;
  onClick?: MouseEventHandler;
  /**
   * The destination of the link
   */
  to?: string;
  color?: string;
  sx?: SxProps<Theme>;
  target?: string;
  dataCy?: string;
}

const Link = ({ children, className, href, onClick, to, color = 'primary', sx, target, dataCy }: Props) => {
  if (href || !to) {
    return (
      <MuiLink
        href={href}
        onClick={onClick}
        component={'a'}
        className={className}
        color={color}
        sx={[...(Array.isArray(sx) ? sx : [sx]), { fontWeight: 'bold' }]}
        target={target}
        data-cy={dataCy}
      >
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      href={href}
      onClick={onClick}
      component={RouterLink}
      className={className}
      to={to}
      color={color}
      sx={[...(Array.isArray(sx) ? sx : [sx]), { fontWeight: 'bold' }]}
      target={target}
      data-cy={dataCy}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
