import { useEffect, useState } from 'react';

/**
 * Hook that prepends a message to the document title.
 * @param {string} message The message to prepend to the document title
 */
const usePageTitleWithMessage = (message: string) => {
  const [originalTitle] = useState(document.title);
  useEffect(() => {
    if (message) {
      document.title = `${message} - ${originalTitle}`;
    } else {
      document.title = originalTitle;
    }

    return () => {
      document.title = originalTitle;
    };
  }, [message, originalTitle]);
};

export default usePageTitleWithMessage;
