import { STORE_CACHE_EXPIRES } from 'config/base';
import { OZ_SESSION_LIVE_MAX_MS } from 'config/constants';

export const now = (): number => Math.ceil(Date.now() / 1000);

export const nowMs = (): number => Date.now();

export const eclipse = (timestampBefore: number): number => nowMs() - timestampBefore;

export const isValidDate = (dateObject: string | number | Date): boolean =>
  dateObject && new Date(dateObject).toString() !== 'Invalid Date';

// Get like `GMT-0400`
// @doc https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
export const getLocalTimezone = (): string => new Date().toString().match(/([A-Z]+[+-][0-9]+)/)[1];

export const isExpired = (prevTs: number): boolean => {
  const diff: number = Date.now() - prevTs;

  return diff > STORE_CACHE_EXPIRES;
};

// Given a start time in seconds, get remaining seconds of an OZ session.
export const getOzRemainingSessionMs = (startTime: number): number => {
  const remaining: number = OZ_SESSION_LIVE_MAX_MS - (new Date().getTime() - new Date(startTime * 1000).getTime());
  // Clock on client side is always not reliable, limit it.
  return remaining > OZ_SESSION_LIVE_MAX_MS || remaining <= 0 ? OZ_SESSION_LIVE_MAX_MS : remaining;
};

/**
 * Util to calculate the time that has passed since a given timestamp
 * @param timestamp - the timestamp to calcuate diff from,
 *  e.g. last fetch of incidents
 * @returns {number} number of ms since timestamp
 */
export const getMsSinceTimestamp = (timestamp: number) => {
  const now = nowMs();
  const diffInMs = now - timestamp;

  return diffInMs;
};

/**
 * Returns the short abbreviation for the timezone
 * @example america/los_angeles could return PDT
 */
export const getTimeZoneAbbreviation = ({
  date,
  timezone,
}: {
  /** The date to get the timezone for */
  date: Date;
  /** The  IANA time zone database name for the timezone */
  timezone?: string;
}) => {
  const timeZoneAbbreviation = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
    timeZone: timezone || undefined,
  })
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName').value;

  return timeZoneAbbreviation;
};
