import { useEffect } from 'react';
import {
  rsiIncidentMark,
  rsiIsMarkingFire,
  rstDuplicateIncidentId,
  rstIsFullScreenPanoForTask,
  rstTaskDisposition,
} from 'data';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * This hook resets the state of the task when the user closes the fullscreen pano
 */
export const useResetTaskOnClosingFullScreenPano = () => {
  const setMark = useSetRecoilState(rsiIncidentMark);
  const setDisposition = useSetRecoilState(rstTaskDisposition);
  const setDuplicateId = useSetRecoilState(rstDuplicateIncidentId);
  const setIsMarkingFire = useSetRecoilState(rsiIsMarkingFire);
  const isFullScreenPanoForTask = useRecoilValue(rstIsFullScreenPanoForTask);

  useEffect(() => {
    if (!isFullScreenPanoForTask) {
      setMark(null);
      setDisposition(null);
      setDuplicateId(null);
      setIsMarkingFire(false);
    }
  }, [isFullScreenPanoForTask, setDisposition, setDuplicateId, setIsMarkingFire, setMark]);
};
