import React from 'react';
import { rspwIsUserPICWorkflow } from 'data';
import { useRecoilValue } from 'recoil';

interface Props {
  /** The content to render wrapped in Feature componetnts */
  children: React.ReactElement<FeatureProps>[] | React.ReactElement<FeatureProps>;
}

/**
 * This is a component for handling rendering Content for the PICWorkflow
 * - Children need to be wrapped in PICFeature components, and marked as enabled or disabled
 */
export default function PICWorkflowFlag({ children }: Props) {
  const isPicWorkflow = useRecoilValue(rspwIsUserPICWorkflow);

  const filteredChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    } else if ((child as React.ReactElement<FeatureProps>).props.enabled === isPicWorkflow) {
      return child;
    }

    return null;
  }).filter(Boolean);

  return <>{filteredChildren}</>;
}

interface FeatureProps {
  /** The content to render */
  children: React.ReactElement<FeatureProps>[] | React.ReactElement<FeatureProps>;
  /**
   * Described wether to show the children if enabled or disabled
   * - If this is true. Show the children when the user is in the PIC Workflow
   * - If this is false. Show the children when the user is not in the PIC Workflow
   */
  enabled: boolean;
}

/**
 * This is a wrapper component for the children of Feature Flags
 * - This allows us to render fallback content for Features in enabled/disabled state
 * - If this has enabled=true, this will render when the user is in the PIC Workflow
 * - If this has enabled=false, this will render when the user is not in the PIC Workflow
 */
function PICFeature({ children }: FeatureProps): React.ReactElement {
  return <>{children}</>;
}

export { PICFeature, PICWorkflowFlag };
