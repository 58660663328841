import { USER_ACTIVITY } from 'config/base';
import { localStorageEffectLiveSync } from 'data';
import { atom } from 'recoil';
import { Activity } from 'types';

export const rsactCurrentActivity = atom<Activity>({
  key: 'rsactCurrentActivity',
  default: null,
  effects: [localStorageEffectLiveSync<boolean>(USER_ACTIVITY, null)],
});
