import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'persistent-side-bar': {
    display: {
      xs: 'none',
      md: 'block',
    },
    width: '35vw',
    minWidth: 400,
    maxWidth: 560,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: '35vw',
      minWidth: 400,
      maxWidth: 560,
      boxSizing: 'border-box',
      zIndex: (theme) => theme.zIndex.snackbar,
    },
  },
};

export default styles;
