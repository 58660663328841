import React, { useRef, useState } from 'react';
import { rstTaskImageUrl } from 'data';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import DetectionImageModal from './DetectionImageModal/DetectionImageModal';
import { useDetectionImageCanvas } from './DetectionImage.helpers';
import styles from './DetectionImage.styles';

export const DetectionImage = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageUrl = useRecoilValue(rstTaskImageUrl);
  const [isOpen, setIsOpen] = useState(false);

  useDetectionImageCanvas(canvasRef);

  if (!imageUrl) {
    return null;
  }

  return (
    <>
      <ButtonBase sx={styles['detection-image']} onClick={() => setIsOpen(true)}>
        <Box
          role='img'
          aria-label='AI Detection with Detection Highlighted with Bounding Box'
          component={'canvas'}
          sx={styles['detection-image']}
          ref={canvasRef}
          data-cy='pic-detection-image'
        />
      </ButtonBase>
      <DetectionImageModal setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
};

export default DetectionImage;
