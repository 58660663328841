import { AuthApis } from 'data/proxyApi';
import { atom, RecoilState, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { ApiResponse, RegisterTokenResponseData, ResetTokenResponseData } from 'types';

/**
 * Gets the list of organizations for the Invite Page
 */
export const rsaGetOrganizations = selector<string[]>({
  key: 'rsaGetOrganizations',
  get: async () => await AuthApis.apiGetAllOrganizations(),
});

/**
 * Wether or not registration is complete
 */
export const rsaRegisterDone: RecoilState<boolean> = atom<boolean>({
  key: 'rsaRegisterDone',
  default: false,
});

export const rsaGetRegisterAccountByToken: (
  param: string,
) => RecoilValueReadOnly<ApiResponse<RegisterTokenResponseData>> = selectorFamily<
  ApiResponse<RegisterTokenResponseData>,
  string
>({
  key: 'rsaGetRegisterAccountByToken',
  get: (token) => async (): Promise<ApiResponse<RegisterTokenResponseData>> => {
    return await AuthApis.apiGetRegisterAccountByToken(token);
  },
});

export const rsaGetResetEmailByToken: (param: string) => RecoilValueReadOnly<ApiResponse<ResetTokenResponseData>> =
  selectorFamily<ApiResponse<ResetTokenResponseData>, string>({
    key: 'rsaGetResetEmailByToken',
    get: (token) => async (): Promise<ApiResponse<ResetTokenResponseData>> => {
      return await AuthApis.apiGetResetEmailByToken(token);
    },
  });
