import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  footer: {
    paddingTop: 2,
    paddingBottom: 2,
    display: 'flex',
    justifyContent: 'center',
  },
};

export default styles;
