import { countryCodes } from 'config/base';
import { MIN_INCIDENT_DATE, MIN_INCIDENT_ID } from 'config/constants';
import { AuthApis } from 'data/proxyApi';
import _startsWith from 'lodash/startsWith';
import { ApiResponse, IncidentLabel, ValidationErrorResponse } from 'types';
import { isLabelInactive, isValidDate } from 'utils';
import * as Yup from 'yup';

import 'yup-phone-lite';

export const invalidSpaceOnly = 'Input with only spaces is not allowed';
export const required = 'Please provide a value for this field';
const invalidEmail = 'Email address is in an incorrect format';
const tosNotAccepted = 'Please check the box to agree to the Terms of Service and Privacy Policy';
export const invalidPhoneNumber = 'Invalid phone number';
export const phoneNotVerifiedError = 'Please verify your phone number';
export const invalidUsPhoneNumber: string = invalidPhoneNumber;
const invalidAuPhoneNumber: string = invalidUsPhoneNumber;
const invalidFutureDate = (v: string): string => `${v} cannot be in the future`;
const startTimeRangeError = 'Incident start cannot be after incident end';
const endTimeRangeError = 'Incident end cannot be before incident start';
export const invalidDateTimeFormat = 'Please enter a valid data/time in format: "yyyy/MM/dd HH:mm"';
const invalidDateRange = 'The date provided is out of the accepted range';
const invalidBaseIncidentIdRange = 'The base incident id provided must be greater than 0';

export const LEADING_TRAILING_SPACE_REGEXP = /^(.*)?\S+(.*)?$/gm;
const HAS_SOME_NON_WHITESPACE_CHAR_REGEXP = /^(.*)\S/m;

const usPhoneSchema: Yup.StringSchema = Yup.string().phone('US', invalidUsPhoneNumber).required(invalidUsPhoneNumber);
const auPhoneSchema: Yup.StringSchema = Yup.string().phone('AU', invalidAuPhoneNumber).required(invalidAuPhoneNumber);

export const noOnlySpaceSchema: Yup.StringSchema = Yup.string().matches(
  LEADING_TRAILING_SPACE_REGEXP,
  invalidSpaceOnly,
);
const noOnlySpaceSchemaMultiLine: Yup.StringSchema = Yup.string().matches(
  HAS_SOME_NON_WHITESPACE_CHAR_REGEXP,
  invalidSpaceOnly,
);
const requiredSchema: Yup.StringSchema = Yup.string()
  .matches(LEADING_TRAILING_SPACE_REGEXP, invalidSpaceOnly)
  .required(required);

/**
 * Validate a password asynchronously against the backend.
 * @remarks
 * This function is meant to be used by `Yup.string.test(..., testfn)`, which expects a `boolean`
 * result. In order to make customize the error message on invalid passwords, we throw
 * {@link Yup.ValidationError} with server-supplied messages.
 * @param password the password to validate.
 * @returns `true` if the password is valid and `false` if the password is blank.
 * @throws Yup.ValidationError if the password is not valid.
 */
async function validatePassword(password: string): Promise<boolean> {
  if (!password || password === '') {
    return false;
  }

  const res: ApiResponse<never | ValidationErrorResponse> = await AuthApis.apiPostPasswordValidate(password);
  if (res?.status === 400) {
    const { errors } = res?.data as ValidationErrorResponse;
    throw new Yup.ValidationError(errors.map((e) => e.message).join(' '), password, 'password');
  }

  return true;
}

const passwordSchema: Yup.StringSchema = Yup.string()
  .required(required)
  .test('valid-password', 'Invalid password', validatePassword);

const getCountrySchema = (
  phonesVerified: Record<string, boolean>,
  input: string,
  errorMessage?: string,
): Yup.StringSchema => {
  const schema: Yup.StringSchema = !input || _startsWith(String(input), '1') ? usPhoneSchema : auPhoneSchema;

  return schema.test('verify', errorMessage || phoneNotVerifiedError, (v) => phonesVerified[v]);
};

export const accountSettingsSchema =
  (phonesVerified: Record<string, boolean>, isUserSetUpForMFA: boolean) => (): object =>
    Yup.object({
      phone: Yup.string()
        .when(['isUserSSO', 'mfa'], {
          is: (isUserSSO: boolean, mfa: boolean) => isUserSSO && mfa && isUserSetUpForMFA,
          then: Yup.lazy((val) =>
            getCountrySchema(phonesVerified, val, 'A phone number is required by your organization'),
          ),
        })
        .when(['mfa', 'notifyPhone', 'isUserSSO'], {
          is: (mfa: boolean, notifyPhone: boolean, isUserSSO: boolean) => {
            if (isUserSSO && mfa && !isUserSetUpForMFA && !notifyPhone) {
              return false;
            }

            return mfa || notifyPhone;
          },
          then: Yup.lazy((val) => getCountrySchema(phonesVerified, val)),
          otherwise: Yup.lazy((val) =>
            countryCodes.includes(val) ? Yup.string().nullable() : getCountrySchema(phonesVerified, val),
          ),
        }),
    });

export const idPDiscoveryValidationSchema = () =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
  });

export const loginValidationSchema = (): object =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
    password: Yup.string().defined(required),
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const forgotValidationSchema = (): object =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
  });

export const phoneVerificationCodeSchema = (): object =>
  Yup.object({
    code: requiredSchema,
  });

export const inviteValidationSchema = () =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
    selectedOrganization: requiredSchema,
  });

// @todo Hack org name for Portland
export const orgIsPge = (orgName: string): boolean => ['OR Portland General Electric'].includes(orgName);

export const resetPasswordValidationSchema = (): object =>
  Yup.object({
    password: passwordSchema,
  });

export const registerSubscriberValidationSchema = (): object =>
  Yup.object({
    firstName: requiredSchema,
    lastName: requiredSchema,
    company: requiredSchema,
    title: noOnlySpaceSchema,
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const registerValidationSchema = (): object =>
  Yup.object({
    firstName: requiredSchema,
    lastName: requiredSchema,
    company: requiredSchema,
    title: noOnlySpaceSchema,
    password: passwordSchema,
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const registerExistingSSOValidationSchema = (): object =>
  Yup.object({
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const registerNewSSOValidationSchema = (): object =>
  Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    title: noOnlySpaceSchema,
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

/**
 *
 * @param isSubscriber - Wether the user is just subscribing for alerts
 * @returns The registration validations chema
 */
export const getRegisterValidationSchema = (isSubscriber: boolean): object => {
  if (isSubscriber) {
    return registerSubscriberValidationSchema;
  }

  return registerValidationSchema;
};

export const updateIncidentValidationSchema = (): object =>
  Yup.object({
    label: requiredSchema,
    name: noOnlySpaceSchema,
    info: noOnlySpaceSchemaMultiLine,
    startTime: Yup.date()
      .required(required)
      .min(MIN_INCIDENT_DATE, invalidDateRange)
      .when(['label', 'endTime'], {
        is: (label: IncidentLabel, endTime: string | number | Date) => isLabelInactive(label) && isValidDate(endTime),
        then: Yup.date().max(Yup.ref('endTime'), startTimeRangeError),
      })
      .typeError(invalidDateTimeFormat)
      .max(new Date(), invalidFutureDate('Incident start')),
    endTime: Yup.date()
      .when('label', {
        is: (label: IncidentLabel) => isLabelInactive(label),
        then: Yup.date().required(required),
      })
      .min(Yup.ref('startTime'), endTimeRangeError)
      .nullable()
      .typeError(invalidDateTimeFormat)
      .min(MIN_INCIDENT_DATE, invalidDateRange)
      .max(new Date(), invalidFutureDate('Incident end')),
    baseIncidentId: Yup.number().integer().min(MIN_INCIDENT_ID, invalidBaseIncidentIdRange),
  });

/** Both US and UA phone numbers have 11 digits. */
export const MAGIC_PHONE_NUMBER_LENGTH = 11;
/**
 * Simply check phone number length
 * @param len
 */
export const phoneLengthIsValid = (len: number): boolean => MAGIC_PHONE_NUMBER_LENGTH === len;
