import { rspEndingOzSession, rspIsStationInOz, rspRemoveOzStation, rssIsStationOzCapable } from 'data';
import { OzApis } from 'data/proxyApi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OzEndedReason } from 'types';

/**
 * Attempts to end the OZ session
 * 1. If no permissions or station is not in OZ - don't end the session
 * 2. If API calls fails, don't do anything
 * 3. If API call succeeds the session will be ended, and we close the OZ player
 */
export function useEndStationOz(stationId: number) {
  const hasOzPermission = useRecoilValue(rssIsStationOzCapable(stationId));
  const isStationInOz = useRecoilValue(rspIsStationInOz(stationId));
  const setEndingOzSession = useSetRecoilState(rspEndingOzSession);
  const removeOzStation = useSetRecoilState(rspRemoveOzStation);

  return {
    endStationOz: async (reason: OzEndedReason) => {
      if (!hasOzPermission || !isStationInOz) {
        return null;
      }

      setEndingOzSession(true);
      try {
        const res = await OzApis.apiDeleteEndStationOz(stationId, reason);

        removeOzStation(stationId);

        return res;
      } catch (e) {
        return null;
      } finally {
        setEndingOzSession(false);
      }
    },
  };
}
