import { PanoUser } from 'types';

/**
 * An account requires MFA if any of the orgs has MFA enabled
 * @param user
 */
export const mfaRequestedByOrg = (user: PanoUser): boolean => {
  return !!(user?.orgs || []).find((org) => org?.mfa);
};

/**
 * An account is considered to have MFA enabled either:
 *  1. If the user has MFA enabled for at least one org
 *  2. If the user has MFA enabled for their account
 * @param user
 */
export const accountMfaEnabled = (user: PanoUser): boolean => {
  const oneOrgHas2faEnabled: boolean = mfaRequestedByOrg(user);
  /** @todo we support sms only for now */
  return oneOrgHas2faEnabled || Boolean(user?.phoneMfa);
};

/** An account has setup notification */
export const accountHasNotificationSetup = (account: PanoUser): boolean => {
  return account && account?.notifyEmail !== undefined && account?.notifyPhone !== undefined;
};

/**
 * An account has changed security settings.
 * @param account
 * @param nextAccount
 */
export const accountSecurityChanged = (account: PanoUser, nextAccount: PanoUser): boolean => {
  const accountFieldValueChanged = (field: keyof PanoUser): boolean => {
    if (account?.[field] || nextAccount?.[field]) {
      if (account?.[field] !== nextAccount?.[field]) {
        return true;
      }
    }

    return false;
  };

  /** If account bearer has changed, it always means Security Settings has been changed. */
  if (accountFieldValueChanged('bearer')) {
    return true;
  }

  /** For an account required to have MFA but not yet setup, there is no
   * `bearer` before and after updating account, but `phoneMfa` is given some value,
   * and need to login again.
   * */
  if (accountFieldValueChanged('phoneMfa')) {
    /** If `phoneMfa` is simply removed, no bearer change, but no need to login again */
    return !!nextAccount?.phoneMfa;
  }

  return false;
};
