import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  link: (theme: Theme) => ({
    fontWeight: theme.typography.fontWeightBold,
  }),
};

export default styles;
