import React from 'react';
import spinner from 'assets/icons/spinner.svg';

import Box from '@mui/material/Box';

import styles from './Loading.styles';

/**
 * A full screen loading indicator
 */
const Loading = () => {
  return (
    <Box sx={styles.preloader}>
      <Box component='img' src={spinner} alt='Loading Pano 360' data-cy='pre-loader' />
    </Box>
  );
};
export default Loading;
