import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'detection-image': {
    width: '100%',
    marginBottom: 1,
  },
};

export default styles;
