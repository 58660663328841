/**
 * rss: recoil store station (rss)
 */
import { StationApis } from 'data/proxyApi';
import { atom, DefaultValue, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import {
  OpticalZoomData,
  OzUpdatedStationParams,
  PanoData,
  RssGetLatestPanoPerMinParams,
  RssGetStationPanoDataParams,
  Station,
  StationsMap,
} from 'types';
import { StationOwnerType } from 'types/enums';
import { getIsStatusDisabled, getStationsOwnerType, replaceArrayIndex } from 'utils';

// User hovers on a station
export const rssHoverStationItemId = atom<number>({
  key: 'rssHoverStationItemId',
  default: 0,
});

/**
 * Last time we fetch the full stations list
 */
export const rssUserStationsTs = atom<number>({
  key: 'rssUserStationsTs',
  default: 0,
});

// Call API to fetch all stations (probably with up to 10k stations)
export const rssUserStations = atom<Station[]>({
  key: 'rssUserStations',
  default: [],
});

/** Whether the stations have been fetched once */
export const rssHaveStationsBeenFetched = atom({
  key: 'rssHaveStationsBeenFetched',
  default: false,
});

export const rssUpdateStationOz = selector<OzUpdatedStationParams>({
  key: 'rssUserStations/update',
  get: () => null, // never call the `get`
  set: ({ get, set }, params) => {
    if (params instanceof DefaultValue) {
      return;
    }

    const stations: Station[] = get(rssUserStations);
    const station: Station = stations.find((s) => s.id === params.id);
    if (station) {
      const updatedStation: Station = {
        ...station,
        ...params,
      };
      set(
        rssUserStations,
        replaceArrayIndex(
          stations,
          stations.findIndex((s) => s.id === params.id),
          updatedStation,
        ),
      );
    }
  },
});

/**
 * Gets a list of user stations sorted by:
 * 1. hackOrder
 * 2. active stations are put at the front
 */
export const rssSortedUserStations: RecoilValueReadOnly<Station[]> = selector<Station[]>({
  key: 'rssSortedUserStations',
  get: ({ get }) => {
    const userStations: Station[] = get(rssUserStations);

    return userStations
      ?.slice()
      ?.sort((a, b) => {
        return a.hackOrder - b.hackOrder;
      })
      ?.sort((a, b) => {
        const isADisabled = getIsStatusDisabled(a.status);
        const isBDisabled = getIsStatusDisabled(b.status);
        if (isADisabled && !isBDisabled) {
          return 1;
        } else if (!isADisabled && isBDisabled) {
          return -1;
        }

        return 0;
      });
  },
});

// A map to get stations easier: { [station id]: station }
export const rssUserStationsMap = selector<StationsMap>({
  key: 'rssUserStationsMap',
  get: ({ get }) => {
    const userStations = get(rssUserStations);

    return userStations.reduce((stationsMap: StationsMap, station) => {
      stationsMap[station.id] = station;

      return stationsMap;
    }, {});
  },
});

// stations owner type: show `Station` for Pano stations, `Camera` for 3rd-party ones.
export const rssStationsOwnerType: RecoilValueReadOnly<StationOwnerType> = selector<StationOwnerType>({
  key: 'rssStationsOwnerType',
  get: ({ get }) => {
    const userStations: Station[] = get(rssUserStations);

    return getStationsOwnerType(userStations);
  },
});

// Get pano images data within a time range for a station
export const rssGetStationPanoData: (
  param: RssGetStationPanoDataParams,
) => RecoilValueReadOnly<(OpticalZoomData | PanoData)[]> = selectorFamily<
  (OpticalZoomData | PanoData)[],
  RssGetStationPanoDataParams
>({
  key: 'rssGetStationPanoData',
  get: (params) => async (): Promise<(OpticalZoomData | PanoData)[]> => await StationApis.apiGetStationPanoData(params),
});

export const rssGetLatestPanoPerMin: (
  param: RssGetLatestPanoPerMinParams,
) => RecoilValueReadOnly<(OpticalZoomData | PanoData)[]> = selectorFamily<
  (OpticalZoomData | PanoData)[],
  RssGetLatestPanoPerMinParams
>({
  key: 'rssGetLatestPanoPerMin',
  get:
    ({ stationId, currentPanoData, isOpticalZoom = false }) =>
    async ({ get }): Promise<(OpticalZoomData | PanoData)[]> => {
      const currentLastFrame: OpticalZoomData | PanoData = currentPanoData[currentPanoData.length - 1];
      if (!currentLastFrame || !currentLastFrame[0]) {
        return null;
      }

      return get(
        rssGetStationPanoData({
          stationId,
          from: isOpticalZoom ? currentLastFrame[1] : currentLastFrame[1] / 60,
          to: 0,
          isOpticalZoom,
        }),
      );
    },
});
