import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'menu-header': {
    padding: 2,
  },
  email: {
    wordBreak: 'break-all',
    fontStyle: 'italic',
  },
  'profile-popver-item': {
    fontSize: '14px !important',
    color: 'inherit !important',
    textDecoration: 'none',
    '&:active,\n  &:hover,\n  &:visited': {
      color: 'inherit',
    },
  },
};

export default styles;
