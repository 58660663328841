import React, { useEffect } from 'react';
import { rspFullScreenStationId } from 'data';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const ScrollToTop = (): React.ReactElement => {
  const fullScreenStationId: number = useRecoilValue(rspFullScreenStationId);
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    document.getElementById('root').scrollTop = 0;
  }, [pathname, fullScreenStationId]);

  return null;
};

export default ScrollToTop;
