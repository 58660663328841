import React from 'react';
import Link from 'componentLibrary/Link';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './LegalInformation.styles';

/**
 * Includes the Pano AI copyright and links to Terms of Service and Privacy Policy
 * @note Included in the footers
 */
export const LegalInformation = () => {
  return (
    <Box sx={styles.footer} data-cy='legal-information'>
      <Typography variant='body2'>©{new Date().getFullYear()} Pano AI &nbsp;|&nbsp;</Typography>
      <Link to='/terms' target='_blank'>
        <Typography sx={styles.link} variant='body2'>
          Terms of Service
        </Typography>
      </Link>
      <Typography variant='body2'>&nbsp;|&nbsp;</Typography>
      <Link to='/privacy' target='_blank'>
        <Typography sx={styles.link} variant='body2'>
          Privacy Policy
        </Typography>
      </Link>
    </Box>
  );
};

export default LegalInformation;
