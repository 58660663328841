import React from 'react';
import {
  rstArea,
  rstPixelLoc,
  rstProductId,
  rstSatelliteModelName,
  rstSatelliteModelVersion,
  rstTaskCoordinates,
  rstTaskDistanceDisplay,
  rstTaskSatelliteName,
  rstTaskSource,
  rstTaskTemperatureDisplay,
  rstTileIndex,
} from 'data';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';

import TaskInfoItem from '../TaskInfoItem';

export const SatelliteInfo = () => {
  const satelliteName = useRecoilValue(rstTaskSatelliteName);
  const distance = useRecoilValue(rstTaskDistanceDisplay);
  const coordinates = useRecoilValue(rstTaskCoordinates);
  const temperature = useRecoilValue(rstTaskTemperatureDisplay);
  const source = useRecoilValue(rstTaskSource);
  const tileIndex = useRecoilValue(rstTileIndex);
  const pixelLoc = useRecoilValue(rstPixelLoc);
  const area = useRecoilValue(rstArea);
  const satelliteModelName = useRecoilValue(rstSatelliteModelName);
  const satelliteModelVersion = useRecoilValue(rstSatelliteModelVersion);
  const productId = useRecoilValue(rstProductId);

  if (source !== 'satellite') {
    return null;
  }

  return (
    <Box>
      <TaskInfoItem title='Tile Index' value={tileIndex.toString()} />
      <TaskInfoItem title='Pixel location' value={pixelLoc.toString()} />
      <TaskInfoItem title='Distance' value={distance} />
      <TaskInfoItem title='Coordinates' value={coordinates.toString()} />
      <TaskInfoItem title='Area' value={area} />
      <TaskInfoItem title='Temperature' value={temperature} />
      <TaskInfoItem title='Product ID' value={productId} />
      <TaskInfoItem title='Satellite Name' value={satelliteName} />
      <TaskInfoItem title='Satellite Model' value={satelliteModelName} />
      <TaskInfoItem title='Satellite Model Version' value={satelliteModelVersion} />
    </Box>
  );
};

export default SatelliteInfo;
