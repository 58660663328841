import React from 'react';
import { rspwIsSaveReminderActive, rspwPICWorkflowState } from 'data';
import { useSaveReminder } from 'hooks';
import { useRecoilValue } from 'recoil';

import Active from './Active';
import Inactive from './Inactive';
import { useResetTaskOnClosingFullScreenPano } from './PICWorkflowStateManager.helpers';
import Searching from './Searching';

export const PICWorkflowStateManager = () => {
  const picWorkflowState = useRecoilValue(rspwPICWorkflowState);
  const isSaveReminderActive = useRecoilValue(rspwIsSaveReminderActive);

  useSaveReminder(isSaveReminderActive);
  useResetTaskOnClosingFullScreenPano();

  if (picWorkflowState === 'inactive') {
    return <Inactive />;
  } else if (picWorkflowState === 'searching') {
    return <Searching />;
  }

  return <Active />;
};

export default PICWorkflowStateManager;
