import { useEffect } from 'react';
import { APP_ENV } from 'config/base';
import { rsaPageWithFullAuthFrozen, rsaUser } from 'data';
import _compact from 'lodash/compact';
import _head from 'lodash/head';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import * as Sentry from '@sentry/react';

import { PRIVATE_ROUTES } from './Router.privateRoutes';

export const getIsSharedPage = (pathname: string) => {
  return /\/i\/[a-z0-9]{5,10}/g.test(pathname);
};

/**
 * If the page a user is currently on is not a shared page clear the store
 */
export const useUpdateIsOnFrozenPage = () => {
  const location = useLocation();
  const setPageWithFullAuthFrozen = useSetRecoilState(rsaPageWithFullAuthFrozen);
  const isSharedPage = getIsSharedPage(location.pathname);

  useEffect(() => {
    if (!isSharedPage) {
      setPageWithFullAuthFrozen('');
    }
  });
};

export const useSetSentryUser = () => {
  const user = useRecoilValue(rsaUser);

  useEffect(() => {
    if (user && APP_ENV !== 'local') {
      Sentry.setContext('user', user);
    }
  }, [user]);
};

export const getIsPrivateRoute = (pathname: string): boolean => {
  const trim = (s: string): string => _head(_compact(s.split('/')));
  const pathCategory: string = trim(pathname);
  const privatePaths: string[] = PRIVATE_ROUTES.map((route) => trim(route.path));

  return privatePaths.includes(pathCategory);
};
