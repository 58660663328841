import React, { useState } from 'react';
import {
  rsiIncidentMark,
  rsiIsMarkingFire,
  rstDuplicateIncidentId,
  rstIsFullScreenPanoForTask,
  rstIsTaskCompletable,
  rstIsTaskCompletionInProgress,
  rstTaskDisposition,
} from 'data';
import useTasks from 'hooks/useTasks/useTasks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PICTaskDisposition } from 'types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import styles from './TaskActions.styles';

export const TaskActions = () => {
  const { completeActiveTask } = useTasks();

  const [isUserAcceptingMoreTasks, setIsUserAcceptingMoreTasks] = useState(true);

  const [disposition, setDisposition] = useRecoilState(rstTaskDisposition);
  const setIsMarkingFire = useSetRecoilState(rsiIsMarkingFire);
  const setDuplicateId = useSetRecoilState(rstDuplicateIncidentId);
  const setMark = useSetRecoilState(rsiIncidentMark);
  const isFullScreenPanoForTask = useRecoilValue(rstIsFullScreenPanoForTask);
  const isTaskCompletable = useRecoilValue(rstIsTaskCompletable);
  const isTaskCompletionInProgress = useRecoilValue(rstIsTaskCompletionInProgress);

  return (
    <Box sx={styles['task-actions-container']}>
      <Box sx={styles['dispostion-box']}>
        <ToggleButtonGroup
          disabled={!isFullScreenPanoForTask}
          value={disposition}
          color='primary'
          exclusive
          onChange={(event, newDisposition) => {
            setDisposition(newDisposition);

            if (newDisposition === PICTaskDisposition.needsReview) {
              setIsMarkingFire(true);
            } else {
              setIsMarkingFire(false);
              setMark(null);
            }

            if (newDisposition !== PICTaskDisposition.duplicate) {
              setDuplicateId(null);
            }
          }}
          aria-label='Disposition task'
        >
          <ToggleButton value={PICTaskDisposition.needsReview} data-cy='pic-fire-button'>
            T2 Review
          </ToggleButton>
          <ToggleButton value={PICTaskDisposition.duplicate} data-cy='pic-duplicate-button'>
            Duplicate
          </ToggleButton>
          <ToggleButton value={PICTaskDisposition.nonAlertableSmoke} data-cy='pic-non-alertable-smoke-button'>
            Non-Alertable Smoke
          </ToggleButton>
          <ToggleButton value={PICTaskDisposition.noSmoke} data-cy='pic-no-fire-button'>
            No Smoke
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              value={isUserAcceptingMoreTasks}
              onChange={() => setIsUserAcceptingMoreTasks((prev) => !prev)}
              data-cy='pic-keep-receiving-checkbox'
            />
          }
          label='Keep Receiving new tasks'
        />
      </FormGroup>
      <Button
        onClick={() => {
          completeActiveTask(isUserAcceptingMoreTasks);
        }}
        variant='contained'
        disabled={!isTaskCompletable || isTaskCompletionInProgress}
        data-cy='pic-complete-task-button'
      >
        Complete Task
      </Button>
    </Box>
  );
};

export default TaskActions;
