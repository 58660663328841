import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  paper: {
    width: '100%',
  },
  'detection-image': {
    width: '100%',
    height: '100%',
  },
  'close-button': {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  'detection-image-dialog': {
    zIndex: (theme) => theme.zIndex.tooltip,
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
  title: { cursor: 'move' },
};

export default styles;
