// A recoil effect to sync with local storage
// @doc https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
export const localStorageEffect =
  <T>(key: string, defaultValue: T) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ setSelf, onSet }: any): void => {
    try {
      const savedValue: string = localStorage.getItem(key);

      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue: T, _: T, isReset: boolean) => {
        isReset
          ? defaultValue
            ? localStorage.setItem(key, JSON.stringify(defaultValue))
            : localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    } catch (e) {
      // Something wrong with localStorage values (maybe outdated)
      localStorage.clear();
      window.location.href = '/login';
    }
  };
