import { CanvasPos } from './common';
import { ByUser, OzStatus } from './enums';
import { Station } from './station';

export type OzSequence = [
  number, // start time of the very last OZ session
  number, // captured time of the very last OZ image
];

export type OzStationsStatus = Record<number, OzStatus>;

/**
 * Information about the OZ session
 */
export type IOzStation = {
  stationId: number;
  loading: boolean;
  pan: number;
  tilt: number;
  zoom: number;
  tiltMax: number;
  tiltMin: number;
  zoomMax: number;
  period: number;
  lastOzSequence: OzSequence;
  /** start time in seconds */
  startTime: number;
  correlationId: string;
  byUser: ByUser;
};

export type UpdateOzStationParams = {
  stationId: number;
  period?: number;
  loading?: boolean;
  pan?: number;
  tilt?: number;
  zoom?: number;
};

export type UpdateOzStationStatusParams = {
  stationId: number;
  status: OzStatus;
};

export type UpdateStationsCanvasPosParams = {
  stationId: number;
  pos: CanvasPos | number[];
  centerPan: number;
};

export type OzUpdatedStationParams = Pick<Station, 'cameras'> & {
  modifyTime?: Station['modifyTime'];
  lastOzSequence?: Station['lastOzSequence'];
  id: number;
};

export type OzEndedApiResponseData = Pick<Station, 'cameras'> & {
  periods: number[][];
};

/** These are the reasons a user's OZ session could be ended */
export enum OzEndedReason {
  timeoutInitial = 'timeoutinitial',
  timeoutDuring = 'timeoutduring',
  userEnded = 'userended',
}

export type RemovePendingPtzParams = {
  stationId: number;
  correlationId: string;
};

export type UpdateStationPtzParams = {
  stationId: number;
  ozImageData: OpticalZoomData[];
};

export type UpdateStationOzPeriodParams = {
  stationId: number;
  ozImageData: OpticalZoomData[];
};

export type UpdateStationOzStartTimeParams = {
  stationId: number;
  startTime: number;
};

export type UpdateStationOzCorrelationIdParams = {
  stationId: number;
  correlationId: string;
};

export interface IOzStations {
  [key: number | string]: IOzStation;
}

export type OpticalZoomData = [
  number, // sequence number
  number, // timestamp
  number, // pan
  number, // tilt
  number, // zoom
  number, // period
  string, // correlation id
];

export type PanoData = [
  /** The rotation number (also called sequence) of the station
   *  There are 60 rotations/sequences per hour
   */
  number,
  /** captureTime */
  number,
];

export type PanoImagesData = OpticalZoomData | PanoData;

type StatusMode = 'pano' | 'manual';

export const STATUS_MODE: Record<string, StatusMode> = {
  PANO: 'pano',
  MANUAL: 'manual',
};

export interface PendingPtzApiCall {
  stationId: number; // station id
  correlationId: string; // correlation-id when firing the API call
  pan: number; // Pan value of the PTZ command
  tilt: number; // Tilt value of the PTZ command
  zoom: number; // Zoom value of the PTZ command
  startTime: number; // Timestamp when firing the PTZ command
}

// A map: station id => PendingPtzApiCall[]
export type PendingPtzApiCallOnStations = Record<number, PendingPtzApiCall[]>;

// A map: correlation id => boolean (fulfilled).
export type FulfilledPendingPtzApiCalls = Record<string, boolean>;

export interface CanvasPosition {
  x: number;
  y: number;
  z: number;
  from?: string;
}

export type UpdateCanvasPosParams = {
  canvasIndex: number;
  posForCanvas: CanvasPosition;
};

// A map: canvas index number => canvas position
export type CanvasPositionMap = Record<number, CanvasPosition>;

// A map to store current user's correlation-id -> timestamp;
export type MyCorrelationIdMap = Record<string, number>;

/**
 * Pan Tilt Zoom settings
 */
export type Ptz = {
  /** The bearing between 0-360 of the camera */
  pan: number;
  /** The angle up or down the camera is tilted  */
  tilt: number;
  /** How far the camera is Zoomed in */
  zoom: number;
};

export type TimeLapseMode = {
  speed: number;
  step: number | (number | number[])[];
};

export interface IncidentMarkPosition {
  /** The X position of the mark on the pano */
  x: number;
  /** The Y position of the mark on the pano */
  y: number;
  /** The X position of the mark on the visible canvas */
  xf?: number;
  /** The X position of the mark on the visible canvas */
  yf?: number;
  /** The ID of the incident */
  incidentId?: number;
}

export interface CanvasPositionInfo {
  /** x offset */
  x0: number;
  /** y offset */
  y0: number;
  /** source image X position */
  sx: number;
  /** source image Y position*/
  sy: number;
  /** Which part of the image it will be placed on. Corresponds to dois */
  p: number;
}
