import { useEffect } from 'react';
import { rspwIsPicWorkflowRequested } from 'data';
import useQuery from 'hooks/useQuery';
import { useSetRecoilState } from 'recoil';

/**
 * Sets wether or not the user has requested the PIC Workflow
 * - Done by a query parameter of 'pictask' equal to 1
 */
export const useSetIsPicWorkflowRequested = () => {
  const query = useQuery();
  const setIsPicWorkflowRequested = useSetRecoilState(rspwIsPicWorkflowRequested);

  useEffect(() => {
    if (query.get('pictask') === '1') {
      setIsPicWorkflowRequested(true);
    }
  }, [query, setIsPicWorkflowRequested]);
};
