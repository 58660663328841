import React from 'react';

import Box from '@mui/material/Box';

import LegalInformation from '../LegalInformation';

import styles from './BasicFooter.styles';

export const BasicFooter = () => {
  return (
    <Box component='footer' sx={styles.footer} data-cy='footer'>
      <LegalInformation />
    </Box>
  );
};

export default BasicFooter;
