import { HAS_SECURITY_SETTINGS_CHANGED, IDP_DISCOVERY_EMAIL } from 'config/base';
import { localStorageEffect } from 'data';
import { AuthApis } from 'data/proxyApi';
import { atom, selector } from 'recoil';

/**
 * @description Calls the log out api, clears local storage, and reloads the page
 * - reloading clears in memory storage, as well causes a redirect to login
 */
export const rsaLogoutUser = selector({
  key: 'rsaLogoutUser',
  get: () => {
    return async () => await AuthApis.apiGetLogout();
  },
});

/**
 * Whether to show auth error modal
 */
export const rsaAuthErrorModalOpen = atom<boolean>({
  key: 'rsaAuthErrorModalOpen',
  default: false,
});

/**
 * Login error message
 */
export const rsaLoginError = atom<string>({
  key: 'rsaLoginError',
  default: '',
});

export const rsaLoginTipText = atom<string>({
  key: 'rsaLoginTipText',
  default: '',
});

/**
 * Whether the security settings has been changed.
 */
export const rsaSecuritySettingsChanged = atom<boolean>({
  key: 'rsaSecuritySettingsChanged',
  default: false,
  effects: [localStorageEffect(HAS_SECURITY_SETTINGS_CHANGED, false)],
});

export const rsaRequestEmail = atom<string>({
  key: 'rsaRequestEmail',
  default: '',
});

export const rsaResetPasswordDone = atom<boolean>({
  key: 'rsaResetPasswordDone',
  default: false,
});

/**
 * The email a user entered on the SSO Login Page
 * - This is used to discover what the IDentity Provider (IDP) is for that user
 */
export const rsaIdPDiscoveryEmail = atom<string>({
  key: 'rsaIdPDiscoveryEmail',
  default: '',
  effects: [localStorageEffect(IDP_DISCOVERY_EMAIL, null)],
});
