import { useEffect } from 'react';
import { rsaIsSystemSuper } from 'data';
import { History } from 'history';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';

export default function useSuperOnly(): void {
  const isSystemSuper: boolean = useRecoilValue(rsaIsSystemSuper);
  const history: History = useHistory();

  useEffect(() => {
    if (!isSystemSuper) {
      history.replace('/forbidden');
    }
  }, [history, isSystemSuper]);
}
