import _isArray from 'lodash/isArray';
import _isEqual from 'lodash/isEqual';
import { atom, DefaultValue, selector } from 'recoil';
import { CanvasPos, UpdateStationsCanvasPosParams } from 'types';

export const rspStationsCanvasPos = atom<Record<number, CanvasPos>>({
  key: 'rspStationsCanvasPos',
  default: {},
});

/**
 * Update stations canvas position
 */
export const rspUpdateStationsCanvasPos = selector<UpdateStationsCanvasPosParams>({
  key: 'rspStationsCanvasPos/update',
  get: () => null, // never call the `get`
  set: ({ set, get }, params) => {
    if (params instanceof DefaultValue) {
      return;
    }

    const { stationId, pos, centerPan } = params;
    if (!stationId || !pos) {
      return;
    }

    const stationsCanvasPos: Record<number, CanvasPos> = get(rspStationsCanvasPos);
    let posObj: CanvasPos = {
      ...(pos as CanvasPos),
      centerPan,
    };

    if (_isArray(pos)) {
      const [x, y, z] = pos;
      posObj = { x, y, z, centerPan };
    }

    if (!_isEqual(posObj, stationsCanvasPos[stationId])) {
      set(rspStationsCanvasPos, {
        ...stationsCanvasPos,
        [stationId]: posObj,
      });
    }
  },
});
