import React, { useEffect, useState } from 'react';
import { rspwIsPicUserAvailableSynced } from 'data';
import useTasks from 'hooks/useTasks/useTasks';
import { useSetRecoilState } from 'recoil';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './Inactive.styles';

export const Inactive = () => {
  const [isRequestingTask, setIsRequestingTask] = useState(false);
  const setIsPicUserAvailable = useSetRecoilState(rspwIsPicUserAvailableSynced);
  const { fetchTask } = useTasks();

  useEffect(() => {
    setIsRequestingTask(false);
  }, [setIsRequestingTask]);

  return (
    <Box sx={styles['inactive-container']}>
      <Typography sx={styles['inactive-text']}>You are inactive in the PIC Workflow Management System</Typography>
      <Box>
        <LoadingButton
          variant='contained'
          loading={isRequestingTask}
          data-cy='pic-go-active-button'
          onClick={async () => {
            setIsRequestingTask(true);

            await fetchTask();

            setIsRequestingTask(false);
            setIsPicUserAvailable(true);
          }}
        >
          Go Active
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Inactive;
