import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  icon: {
    height: '16px',
    width: '16px',
    verticalAlign: 'bottom',
    marginBottom: 0.25,
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  tooltipHeader: (theme: Theme) => ({
    fontWeight: theme.typography.fontWeightSemiBold,
  }),
};

export default styles;
