import { initApiToken } from 'config/api';
import { rsaFrozenUser, rsaPageWithFullAuthFrozen, rsaUser, rscDataConflictError, rscGlobalSnackbar } from 'data';
import { AuthApis } from 'data/proxyApi';
import { Location } from 'history';
import { Base64 } from 'js-base64';
import { useLocation, useParams } from 'react-router';
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from 'recoil';
import { GenericFunctionType, GlobalSnackbarParams, PanoUser } from 'types';

import useLogout, { UnauthorizedHandler } from './useLogout';

interface ReturnedType {
  freezeFullAuth: GenericFunctionType;
}

export function useFreezeFullAuth(): ReturnedType {
  const [user, setUser] = useRecoilState(rsaUser);
  const setFrozenUser: SetterOrUpdater<PanoUser> = useSetRecoilState(rsaFrozenUser);
  const setPageWithFullAuthFrozen: SetterOrUpdater<string> = useSetRecoilState(rsaPageWithFullAuthFrozen);
  const setGlobalSnackbar: SetterOrUpdater<GlobalSnackbarParams> = useSetRecoilState(rscGlobalSnackbar);
  const setDataConflictError: SetterOrUpdater<string> = useSetRecoilState(rscDataConflictError);
  const logout: UnauthorizedHandler = useLogout({ callLogoutApi: false });

  const location: Location = useLocation();
  const isOnSharedPage: boolean = location.pathname.indexOf('/i/') >= 0;
  const { token }: { token?: string; id?: string } = useParams();

  return {
    freezeFullAuth: (): void => {
      if (isOnSharedPage && token) {
        setPageWithFullAuthFrozen(location.pathname);
        setFrozenUser(user);
        const anonymous: PanoUser = AuthApis.getAnonymousUser(Base64.encode(`incident:${token}`));
        setUser(anonymous);
        initApiToken(logout, anonymous, setGlobalSnackbar, setDataConflictError);
      }
    },
  };
}
