import React from 'react';
import Alert from 'componentLibrary/Alert';
import MESSAGES, { getDataConflictTitle } from 'config/messages';
import { rscDataConflictError } from 'data';
import { Location } from 'history';
import { useRefreshAccount } from 'hooks';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const DataConflictModal = () => {
  const [dataConflictError, setDataConflictError] = useRecoilState(rscDataConflictError);
  const location: Location = useLocation();

  useRefreshAccount();

  const onOk = () => {
    if (dataConflictError === 'account') {
      setDataConflictError('');
    } else {
      window.location.href = location.pathname;
    }
  };

  if (!dataConflictError) {
    return null;
  }

  const errorMessage =
    MESSAGES.DATA_CONFLICTS[dataConflictError.toUpperCase() as 'GENERIC' | 'INCIDENT' | 'MARK' | 'ACCOUNT'];

  if (!errorMessage) {
    return null;
  }

  return (
    <Dialog open={!!dataConflictError} fullWidth maxWidth='sm'>
      <DialogTitle>{getDataConflictTitle(dataConflictError)}</DialogTitle>
      <DialogContent>
        <Alert type='error'>
          {errorMessage.map((para) => (
            <Typography key={para}>{para}</Typography>
          ))}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button size='small' color='primary' variant='contained' onClick={onOk} data-cy='data-conflict-ok-button'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataConflictModal;
