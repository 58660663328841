import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'inactive-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
    flexGrow: 1,
  },
  'inactive-text': {
    textAlign: 'center',
    marginBottom: 8,
  },
};

export default styles;
