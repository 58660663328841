/**
 * This file is for managing incident state that is fetched byId
 * - Mainly to be used on the incident details page
 */

import { METERS_TO_MILE } from 'config/base';
import { IncidentApis } from 'data/proxyApi';
import { rsaIsMetric, rsaUserCoordinateSystem } from 'data/store/authStore';
import { selectorFamily } from 'recoil';
import { CoordinatesForDisplay, CoordinateSystem, Incident, IncidentCamera, IncidentLabel } from 'types';
import { getGDACoordinatesFromLonLat, getIncidentLabel, stringifyGDACoordinates, stringifyLonLat } from 'utils';

import { rssUserStationsMap } from '../../stationStore';
import { rsiUserIncidents } from '../incidentStore';

/**
 * @description Returns the Incident with the given id or token
 * Pulls from rsiUserIncidents, and fetches if not found
 * - NOTE: To just pull without fetching use rsiIncidentById
 */
export const rsiFetchIncidentById = selectorFamily<Incident, number | string>({
  key: 'rsiFetchIncidentById',
  get:
    (
      /** Incident Id or token */
      incidentIdOrToken,
    ) =>
    async ({ get }) => {
      const incidents = get(rsiUserIncidents);

      const incident = incidents?.find((incident) => {
        return incident.id.toString() === incidentIdOrToken.toString();
      });

      if (incident) {
        return incident;
      }

      try {
        const response = await IncidentApis.apiGetIncidentById(incidentIdOrToken);

        return response;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiIncidentCamerasById = selectorFamily<IncidentCamera[], number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const userStationsMap = get(rssUserStationsMap);

      return (
        incident?.cameras?.filter((camera) => !!camera.mark).filter((camera) => userStationsMap[camera?.id]?.name) || []
      );
    },
});

/**
 * Returns the primary incident camera for the incident with the given id
 */
export const rsiPrimaryIncidentCameraById = selectorFamily<IncidentCamera, number>({
  key: 'rsiPrimaryIncidentCameraById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const incidentCameras = get(rsiIncidentCamerasById(incidentId));
      const primaryId = incident?.primary;

      return incidentCameras.find((camera) => camera.id === primaryId) || null;
    },
});

/**
 * Returns the primary incident camera's error for the incident with the given id
 */
export const rsiPrimaryIncidentCameraGeoLocationErrorById = selectorFamily<number, number>({
  key: 'rsiPrimaryIncidentCameraById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incidentCamera = get(rsiPrimaryIncidentCameraById(incidentId));
      const isMetric = get(rsaIsMetric);
      const nearDistanceKm = incidentCamera?.geolocation?.nearDistanceKm;
      const farDistanceKm = incidentCamera?.geolocation?.farDistanceKm;
      if (nearDistanceKm !== undefined && farDistanceKm !== undefined) {
        const errorKm = (farDistanceKm - nearDistanceKm) / 2;
        const error = isMetric ? errorKm : errorKm * METERS_TO_MILE * 1000;

        return Number(error.toFixed(2));
      }

      return null;
    },
});

/**
 * Returns the geolocation centroid for the primary incident camera
 * @note the centroid is the estimates location of the incident
 * when using single station incident location range (SSILR) estimation
 */
export const rsiPrimaryCentroidById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rsiPrimaryCentroidById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incidentCamera = get(rsiPrimaryIncidentCameraById(incidentId));
      const centroid = incidentCamera?.geolocation?.centroid;
      const coordinateSystem = get(rsaUserCoordinateSystem);

      if (!centroid) {
        return null;
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return [
          {
            coordinateSystem: CoordinateSystem.GDA2020,
            coordinateString: stringifyGDACoordinates(getGDACoordinatesFromLonLat(centroid)),
          },
          {
            coordinateString: stringifyLonLat(centroid),
            coordinateSystem: CoordinateSystem.WGS84,
          },
        ];
      }

      return [
        {
          coordinateString: stringifyLonLat(centroid),
          coordinateSystem: CoordinateSystem.WGS84,
        },
      ];
    },
});

/**
 * @description Returns all available coordinates for the incident
 * - Will return GDA2020 if that GCS is available for any user org
 */
export const rsiIncidentTriangulatedCoordinatesById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rsiIncidentTriangulatedCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      const lonLat = { lat: incident?.lat, lon: incident?.lon };

      if (!incident || !incident?.lat || !incident?.lon) {
        return null;
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return [
          {
            coordinateSystem: CoordinateSystem.GDA2020,
            coordinateString: stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat)),
          },
          {
            coordinateString: stringifyLonLat(lonLat),
            coordinateSystem: CoordinateSystem.WGS84,
          },
        ];
      }

      return [
        {
          coordinateString: stringifyLonLat(lonLat),
          coordinateSystem: CoordinateSystem.WGS84,
        },
      ];
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiFetchIncidentLabelById = selectorFamily<IncidentLabel, number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));

      return getIncidentLabel(incident);
    },
});
