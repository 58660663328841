import React, { useRef } from 'react';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';

import { useDetectionImageCanvas } from '../DetectionImage.helpers';

import DetectionImageCanvas from './DetectionImageCanvas';
import styles from './DetectionImageModal.styles';

/**
 * @description The draggable Paper component for the dialog
 * @ref https://mui.com/material-ui/react-dialog/#draggable-dialog
 */
function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} sx={styles['paper']} />
    </Draggable>
  );
}

export interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const DetectionImageModal = ({ isOpen, setIsOpen }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useDetectionImageCanvas(canvasRef);

  return (
    <Dialog
      maxWidth={'xl'}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
      sx={styles['detection-image-dialog']}
      slots={{ backdrop: () => <Box sx={styles['backdrop']} /> }}
    >
      <DialogTitle sx={styles['title']} id='draggable-dialog-title'>
        Detection Image
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => setIsOpen(false)} sx={styles['close-button']}>
        <CloseIcon />
      </IconButton>
      <DetectionImageCanvas />
    </Dialog>
  );
};

export default DetectionImageModal;
