import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import _has from 'lodash/has';
import _pick from 'lodash/pick';
import { GetIncidentsQueryParams, Incident, INCIDENT_LABEL, IncidentCamera, LonLat } from 'types';
import { compactObject, lngLatForApi, now, processIncidentFromApi } from 'utils';

interface GetIncidentsHeaders {
  /** Return incidents that have occurred since this time */
  ifRange?: number;
  /** A value to append to `X-Activity-Test` for testing purposes */
  testHeader?: string;
}

type GetIncidentParmeters = GetIncidentsHeaders & GetIncidentsQueryParams;

export default class IncidentApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    const api: AxiosInstance = await authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * This creates the query string parameters for the incidents API call
   */
  getIncidentsQueryString({ stationId, limit, status, keyword, view }: GetIncidentsQueryParams) {
    const params = new URLSearchParams();

    if (stationId) {
      params.append('ec_id', stationId.toString());
    }
    if (limit) {
      params.append('limit', limit.toString());
    }
    if (keyword) {
      params.append('keyword', keyword);
    }
    if (view) {
      params.append('view', view);
    }
    if (status) {
      const statusMap: Record<string, string> = {
        possible: 'INVESTIGATION',
        confirmed: 'ALERTED',
        closed: 'CLOSED',
        dismissed: 'DISMISSED',
      };
      status?.forEach((value) => {
        const mappedStatus = statusMap[value];

        if (mappedStatus) {
          params.append('status', mappedStatus);
        }
      });
    }

    return params.toString();
  }

  async apiGetIncidents(options?: GetIncidentParmeters): Promise<Incident[]> {
    const { ifRange = 0, stationId, limit, status, keyword, shouldSkipErrorHandling, testHeader, view } = options;

    const headers: Record<string, string> = ifRange
      ? {
          'If-Range': new Date(ifRange).toUTCString(),
          'X-Test-Key': testHeader,
        }
      : {
          'X-Test-Key': testHeader,
        };
    const queryString = this.getIncidentsQueryString({ stationId, limit, status, keyword, view });

    try {
      const { data }: { data: Incident[] } = await this.api.get(`/incidents${queryString ? `?${queryString}` : ''}`, {
        headers,
        shouldSkipErrorHandling,
      });

      data.forEach(processIncidentFromApi);

      return data;
    } catch (e) {
      console.error('[Pano] API Error incidents', e);

      return [];
    }
  }

  async apiGetIncidentById(id: number | string): Promise<Incident> {
    try {
      const { data } = await this.api.get(`/incident/${id}`);
      processIncidentFromApi(data);

      return data;
    } catch (err) {
      throw new Error(`[Pano] API Error: ${JSON.stringify(err)}`);
    }
  }

  async apiPutUpdateIncident(incident: Incident, existingLabel = '', labelChanged = true): Promise<Incident> {
    try {
      const updatedIncident: Incident = {
        ...compactObject(incident),
        ...lngLatForApi(_pick(incident, ['lon', 'lat']) as LonLat),
      } as Incident;

      // delete front-end only fields.
      delete updatedIncident.order;
      delete updatedIncident.stringified;

      // Keep only necessary fields for incident cameras
      if (updatedIncident.cameras) {
        updatedIncident.cameras = updatedIncident.cameras.map(
          (c) =>
            _pick(c, ['id', 'bearing', 'bearingError', 'distance', 'ordinal', 'mark', 'createTime']) as IncidentCamera,
        );
      }

      if (incident.label === INCIDENT_LABEL.POSSIBLE) {
        delete updatedIncident.label;
      }

      // If label changes, update `closeTime` and `label` accordingly
      if (labelChanged) {
        if (updatedIncident.label === INCIDENT_LABEL.DISMISSED) {
          delete updatedIncident.label;
          // Set closeTime only if it is not set
          if (!_has(updatedIncident, 'closeTime')) {
            updatedIncident.closeTime = now();
          }
        } else if (updatedIncident.label === INCIDENT_LABEL.CLOSED) {
          // When incident is `possible`, the `label` could be not set.
          // If set `closeTime` without label, it becomes `dismissed`, so default the label to be `confirmed`.
          updatedIncident.label = existingLabel || INCIDENT_LABEL.CONFIRMED;
          // Set closeTime only if it is not set
          if (!_has(updatedIncident, 'closeTime')) {
            updatedIncident.closeTime = now();
          }
        } else {
          delete updatedIncident.closeTime;
          delete updatedIncident.endTime;
        }
      } else {
        // If label does not change, restore the label to the original one
        updatedIncident.label = existingLabel;
        if (!existingLabel) {
          delete updatedIncident.label;
        }
      }

      const endpoint: string = updatedIncident.id ? `/incident/${updatedIncident.id}` : '/incident';
      const method: 'put' | 'post' = updatedIncident.id ? 'put' : 'post';

      const { data } = await this.api[method](endpoint, updatedIncident, { shouldSkipErrorHandling: true });
      processIncidentFromApi(data);

      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async apiGetShareAccessToken(id: number): Promise<string> {
    try {
      const { data } = await this.api.post(`/incident/${id}/token`);

      return data;
    } catch (err) {
      throw new Error(`[Pano] API Error: ${JSON.stringify(err)}`);
    }
  }

  /**
   * Merge the smoke investigation incident into the base incident
   * @param baseIncidentId The incident to merge into
   * @param smokeInvestigationId The incident to merge
   * @returns The updated base incident
   */
  async apiPostMergeIncident(baseIncidentId: number, smokeInvestigationId: number): Promise<Incident> {
    try {
      const { data } = await this.api.post(`/incident/${smokeInvestigationId}:merge`, { baseIncidentId });

      return data;
    } catch (err) {
      throw new Error(`[Pano] API Error: ${JSON.stringify(err)}`);
    }
  }
}
