import _get from 'lodash/get';
import { GenericFunctionType } from 'types';

export const copy2Clipboard = (
  text: string,
  onCopied: (_flag: boolean) => void,
  onError = (): void => {
    // do nothing
  },
): void => {
  const writeText: (_data: string) => Promise<void> = _get(window, 'navigator.clipboard.writeText', null);

  const failedToCopy = (e?: unknown): void => {
    if (e) {
      console.debug('Failed to copy to clipboard', e);
    }
    onError();
  };

  if (writeText) {
    const { navigator } = window;
    navigator.clipboard.writeText(text).then(() => onCopied(true), failedToCopy);
  } else {
    try {
      const input: HTMLElementTagNameMap['span'] = document.createElement('span');
      document.body.appendChild(input);
      input.innerHTML = text;
      const range: Range = document.createRange();
      range.selectNode(input);
      window.getSelection().addRange(range);
      const successful: boolean = document.execCommand('copy');
      if (successful) {
        onCopied(true);
      } else {
        failedToCopy();
      }
    } catch (e: unknown) {
      failedToCopy(e);
    }
  }
};

export const isIPhone = (): boolean => /iPhone/i.test(window.navigator.userAgent);
export const isIPad = (): boolean => /iPad/i.test(window.navigator.userAgent);
export const isAndroid = (): boolean => /Android/i.test(window.navigator.userAgent);
export const isFireFox = (): boolean => /FireFox/i.test(window.navigator.userAgent);

/**
 * @deprecated - use MUI useMediaQuery
 * https://mui.com/material-ui/react-use-media-query/#basic-media-query
 */
export const isMobileDevice = (): boolean => isIPhone() || isIPad() || isAndroid();

export const isHomePage = (pathname: string): boolean => pathname === '/' || pathname === '/map/incidents';

// Check whether it's on page with OZ players
export const isOnPageWithOzPlayer = (): boolean => {
  const { pathname } = window.location;

  return (
    /\/map\/stations\/panos/g.test(pathname) ||
    /\/incident\/[0-9]+$/g.test(pathname) ||
    /\/station\/[0-9]+$/g.test(pathname) ||
    /\/i\/[a-zA-Z0-9]+$/g.test(pathname)
  );
};

/**
 * When user bearer token expires, redirect to login page
 */
export const redirectToLogin: GenericFunctionType = (): void => {
  window.location.href = `/login`;
};
