import { AxiosError, AxiosResponse } from 'axios';
import { RESPONSE_CODE_ERROR_MESSAGES } from 'config/messages';
import { GlobalSnackbarParams, Severity } from 'types';
import { reportErrorMessage } from 'utils';

export const getDataConflictErrorType = ({ method }: { method: string }) => {
  if (/incident.*\/edit/i.test(window.location.href) && method === 'put') {
    return 'incident';
  } else if (/incident/i.test(window.location.href) && method === 'put') {
    return 'mark';
  }

  return 'generic';
};

export const getErrorMessage = (statusCode?: number) => {
  if (!statusCode) {
    return RESPONSE_CODE_ERROR_MESSAGES.FALLBACK;
  } else if (RESPONSE_CODE_ERROR_MESSAGES[statusCode]) {
    return RESPONSE_CODE_ERROR_MESSAGES[statusCode];
  }

  return `Error Code ${statusCode}: ${RESPONSE_CODE_ERROR_MESSAGES.FALLBACK}`;
};

export const errorHandler = ({
  error,
  requireLogin,
  setDataConflictError,
  setGlobalSnackbar,
}: {
  error: AxiosError;
  requireLogin: (_response: AxiosResponse) => void;
  setDataConflictError: (_type: string) => void;
  setGlobalSnackbar: (_alert: GlobalSnackbarParams) => void;
}) => {
  const statusCode = error?.response?.status;
  const method = error?.response?.config?.method;

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (statusCode === 401) {
    requireLogin(error?.response);

    return Promise.reject(error);
  } else if (statusCode === 422) {
    setDataConflictError(getDataConflictErrorType({ method }));

    return Promise.reject(error);
  } else if (error.config.shouldSkipErrorHandling) {
    reportErrorMessage(`API Error - ${JSON.stringify(error)}`);

    return Promise.reject(error);
  }

  const errorMessage = getErrorMessage(statusCode);
  setGlobalSnackbar({ message: errorMessage, severity: Severity.Error });
  reportErrorMessage(`API Error - ${JSON.stringify(error)}`);

  return Promise.reject(error);
};
