import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'pic-page-wrapper': {
    display: 'flex',
  },
  'main-content': {
    flexGrow: 1,
  },
  'side-bar-content': {
    minHeight: 320,
    padding: 4,
    paddingTop: 8,
    boxSizing: 'content-box',
  },
};

export default styles;
