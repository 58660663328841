import { useEffect } from 'react';
import { rsaIsFullAuth, rsaPageWithFullAuthFrozen, rsaUser, rscDataConflictError } from 'data';
import { AuthApis } from 'data/proxyApi';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { PanoUser } from 'types';

// Hook to refresh account
export function useRefreshAccount(): void {
  const isFullAuth: boolean = useRecoilValue(rsaIsFullAuth);
  const pageWithFullAuthFrozen: string = useRecoilValue(rsaPageWithFullAuthFrozen);
  const setUser: SetterOrUpdater<PanoUser> = useSetRecoilState(rsaUser);
  const dataConflictError: string = useRecoilValue(rscDataConflictError);

  useEffect(() => {
    async function refresh(): Promise<void> {
      if (pageWithFullAuthFrozen || isFullAuth) {
        return;
      }

      if (dataConflictError !== 'account') {
        return;
      }

      const refreshedAccount: PanoUser = await AuthApis.apiGetRefreshAccount();
      if (refreshedAccount) {
        setUser(refreshedAccount);
      }
    }

    refresh();
  }, [dataConflictError, isFullAuth, pageWithFullAuthFrozen, setUser]);
}
