import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'profile-button': {
    minWidth: 40,
    color: 'white.main',
    padding: 0,
    borderRadius: 0.5,
  },
  'menu-container': {
    width: 200,
    padding: 0,
  },
};

export default styles;
