import { TASK_POLLING_INTERVAL } from 'config/base';
import useTasks from 'hooks/useTasks';

import useInterval from '@use-it/interval';

/**
 * This attemps to poll for a new task
 */
export const usePollForNewTask = () => {
  const { fetchTask } = useTasks();

  useInterval(() => {
    fetchTask();
  }, TASK_POLLING_INTERVAL);
};
