/**
 * Syncs the atom to localstorage, and broadcasts it to the other tabs using broadcast channels
 * This creates a unique broadcast channel per value. So we want to limit usage to when necessary.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API More info about the Broadcast Channel API}
 * @note - broadcast channels are limited to 1 request per second
 * - So if you're syncing multiple values, you'll need multiple channels
 * - Broadcast channels can get expensive, so try to use 1 as much as possible
 * @see {@link https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence For a recoil example on a local storage effect}
 */
export const localStorageEffectLiveSync =
  <T>(key: string, defaultValue: T) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ setSelf, onSet }: any): void => {
    const broadcastChannel = new BroadcastChannel(key);
    try {
      const savedValue: string | null = localStorage.getItem(key);

      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      } else {
        setSelf(defaultValue);
        localStorage.setItem(key, JSON.stringify(defaultValue));
      }

      broadcastChannel.onmessage = (event) => {
        const newValue: T = event.data;

        setSelf(newValue);
      };

      onSet((newValue: T, _: T, isReset: boolean) => {
        if (isReset && defaultValue) {
          localStorage.setItem(key, JSON.stringify(defaultValue));
          broadcastChannel.postMessage(defaultValue);
        } else if (isReset && !defaultValue) {
          localStorage.removeItem(key);
          broadcastChannel.postMessage(null);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
          broadcastChannel.postMessage(newValue);
        }
      });
    } catch (e) {
      // Something wrong with localStorage values (maybe outdated)
      localStorage.clear();
      window.location.href = '/login';
    }
  };
