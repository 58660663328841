import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'detection-canvas': {
    width: '100%',
    height: '100%',
  },
};

export default styles;
