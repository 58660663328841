import React, { useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';

import UserMenuItems from './UserMenuItems/UserMenuItems';
import styles from './UserMenus.styles';

const UserMenus = () => {
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

  const handleProfilePopoverClose = () => setProfileMenuAnchorEl(null);

  const handleProfilePopoverClick = (e: React.MouseEvent) => {
    setProfileMenuAnchorEl(e.currentTarget);
  };

  const isOpen = Boolean(profileMenuAnchorEl);

  return (
    <Box sx={styles['profile-icon']}>
      <Button
        aria-label='profile'
        onClick={handleProfilePopoverClick}
        data-cy='profile-button'
        variant='contained'
        size='small'
        sx={styles['profile-button']}
      >
        <PersonIcon />
      </Button>
      <Popover
        open={isOpen}
        anchorEl={profileMenuAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleProfilePopoverClose}
      >
        <MenuList sx={styles['menu-container']}>
          <UserMenuItems setProfileMenuAnchorEl={setProfileMenuAnchorEl} />
        </MenuList>
      </Popover>
    </Box>
  );
};

export default UserMenus;
