import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  alert: (theme: Theme) => ({
    padding: 1,
    borderRadius: 0.5,
    lineHeight: 1.3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    marginBottom: 2,
    width: '100%',
    fontSize: theme.typography.body2,
    flexDirection: 'column',
  }),
  success: (theme: Theme) => ({
    backgroundColor: theme.palette.success.light,
  }),
  error: (theme: Theme) => ({
    background: theme.palette.errorExtra.extraLight,
    '& .MuiLink-root': {
      color: 'primary.dark',
    },
  }),
};

export default styles;
