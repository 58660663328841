import { AxiosError, AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { Activity } from 'types/activity';

export default class ActivityApi {
  api: AxiosInstance;
  isExtendingActivity: boolean;

  constructor() {
    this.init();
    this.isExtendingActivity = false;
  }

  async init(): Promise<void> {
    const api: AxiosInstance = authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * @description Creates a new activity for a user
   * - This is currently used for tracking PIC users activity in Pano
   * @param testHeader - A value to append to `X-Activity-Test` for testing purposes
   */
  async apiCreateActivity(testHeader?: string): Promise<Activity> {
    try {
      const { data } = await this.api.post(`/activity`, null, {
        headers: {
          'X-Activity-Test': testHeader,
        },
      });

      return data;
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 403) {
        console.error('[Pano] API Error Activity', e);

        return null;
      } else if ((e as AxiosError)?.response?.status === 409) {
        return (e as AxiosError)?.response.data as Activity;
      }
      console.error('[Pano] API Error Activity', e);

      return null;
    }
  }

  /**
   * @description Extends the end time of the activity associated with the current Id
   * - If 410s or 404s we'll create a new activity
   */
  async apiExtendActivityById(id: Activity['id']): Promise<Activity> {
    try {
      const { data } = await this.api.post(`/activity/${id}:extend`);

      return data;
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 410 || (e as AxiosError)?.response?.status === 404) {
        return await this.apiCreateActivity();
      }
      console.error('[Pano] API Error Activity', e);

      return null;
    }
  }

  /**
   * @description Extends the end time of the activity associated with the current Id
   */
  async apiEndActivityById(id: Activity['id']): Promise<Activity> {
    try {
      const { data } = await this.api.post(`/activity/${id}:end`);

      return data;
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 410) {
        console.error('[Pano] API Error Activity', e);
      }
      console.error('[Pano] API Error Activity', e);

      return null;
    }
  }
}
