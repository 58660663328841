import React from 'react';

import { useUnfreezeUser } from './UserInitializer.helpers';

/**
 * This component handles initializing the user.
 * This needs to be a sibling of any component that makes API calls.
 * If a parent of this tries to make an API call on mount it will fail.
 * That's because the interceptors won't have been appended
 */
export const UserInitializer = () => {
  useUnfreezeUser();

  return <></>;
};

export default UserInitializer;
