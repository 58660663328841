import React from 'react';
import Link from 'componentLibrary/Link';
import {
  rstTaskConfidenceDisplay,
  rstTaskDateDisplay,
  rstTaskDateDisplayLabel,
  rstTaskRedirectUrl,
  rstTaskStationDisplay,
  rstTaskTitle,
} from 'data';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DetectionImage from './DetectionImage/DetectionImage';
import CameraInfo from './CameraInfo';
import KnownFireInfo from './KnownFireInfo';
import SatelliteInfo from './SatelliteInfo';
import styles from './TaskInfo.styles';
import TaskInfoItem from './TaskInfoItem';

export const TaskInfo = () => {
  const confidenceDisplayText = useRecoilValue(rstTaskConfidenceDisplay);
  const stationDisplayText = useRecoilValue(rstTaskStationDisplay);
  const dateDisplayText = useRecoilValue(rstTaskDateDisplay);
  const titleDisplayText = useRecoilValue(rstTaskTitle);
  const taskRedirectUrl = useRecoilValue(rstTaskRedirectUrl);
  const dateDisplayLabel = useRecoilValue(rstTaskDateDisplayLabel);

  return (
    <Box component='section' sx={styles['task-info-container']}>
      <Typography component='h2' variant='h5' gutterBottom>
        {titleDisplayText}
      </Typography>
      <Box>
        <DetectionImage />
        <TaskInfoItem title='Confidence' value={confidenceDisplayText} />
        <TaskInfoItem
          title='Station'
          value={
            <Link sx={{ userSelect: 'auto' }} to={taskRedirectUrl}>
              {stationDisplayText}
            </Link>
          }
        />
        <TaskInfoItem title={dateDisplayLabel} value={dateDisplayText} />
        <SatelliteInfo />
        <KnownFireInfo />
        <CameraInfo />
      </Box>
    </Box>
  );
};

export default TaskInfo;
