import { useEffect } from 'react';

/**
 * This is a reusable hook to trigger a browser-generated confirmation dialog that asks users to confirm if they really want to leave the page
 * @param isActive  Wether or not the SaveReminder should turn on
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event} for more info about beforeUnload.
 */
export const useSaveReminder = (isActive = false) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    };

    if (isActive) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (isActive) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [isActive]);
};

export default useSaveReminder;
