import { useEffect } from 'react';
import { rsaRegisterDone, rsaResetPasswordDone } from 'data';
import { Resetter, useResetRecoilState } from 'recoil';

// reset `registerDone` and `resetPasswordDone` to be `false`.
export function useResetAuthStatus(): void {
  const resetRegisterDone: Resetter = useResetRecoilState(rsaRegisterDone);
  const resetResetPasswordDone: Resetter = useResetRecoilState(rsaResetPasswordDone);

  useEffect(() => {
    resetRegisterDone();
    resetResetPasswordDone();
  }, [resetRegisterDone, resetResetPasswordDone]);
}
