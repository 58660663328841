import React from 'react';

import Drawer from '@mui/material/Drawer';

import styles from './PersistentSideBar.styles';

export interface Props {
  children: React.ReactNode;
}

/**
 * This is a persistent sidebar
 * - It will only be visible for screens larger than 900 px
 */
export const PersistentSideBar = ({ children }: Props) => {
  return (
    <Drawer variant='permanent' sx={styles['persistent-side-bar']} data-cy='side-bar'>
      {children}
    </Drawer>
  );
};

export default PersistentSideBar;
