import { IncidentNotificationType } from 'types';

type DataConflictMessages = {
  GENERIC: string[];
  INCIDENT: string[];
  MARK: string[];
  ACCOUNT: string[];
};

type NotificationWarningMessages = {
  UPDATE_INCIDENT: string[];
  CREATE_INCIDENT: string[];
};

type MessagesType = {
  DEFAULT_ERROR: string;
  DEFAULT_ERROR_LOGIN: string;
  MARK_FIRE_DISABLED: string;
  ACCESS_TOKEN_INVALID: string;
  API_FORBIDDEN_ERROR: string;
  API_FORMAT_ERROR: string;
  API_GENERAL_ERROR: string;
  API_WEATHER_ERROR: string;
  DATA_CONFLICTS: DataConflictMessages;
  ACCOUNT_INSYNC: string;
  LOGOUT_FAILED: string;
  NOTIFICATION_WARNINGS: NotificationWarningMessages;
  INVITE_FAILED: string;
  INVITE_SENT: (email: string, org: string) => string;
  COMMUNITY_DUPLICATE_INVITE: (email: string, org: string) => string;
  OZ_CONTROL_ERR_PAN: string;
  OZ_CONTROL_ERR_TILT_MIN: string;
  OZ_CONTROL_ERR_TILT_MAX: string;
  OZ_CONTROL_ERR_ZOOM_MIN: string;
  OZ_CONTROL_ERR_ZOOM_MAX: string;
  OZ_UPDATE_ERR: string;
  OZ_QUEUE_IS_FULL: string;
  OZ_CAMERA_MOVING: string;
  MFA_CODE_EXPIRED: string;
  MFA_CODE_INVALID: string;
  MFA_CODE_FAILED_TO_SEND: string;
  DETECTION_IMAGE_FAILED_TO_LOAD: string;
};

/**
 * These are the respsone code error meesages
 */
export const RESPONSE_CODE_ERROR_MESSAGES: {
  [key: number | string]: string;
} = {
  400: 'Your request was unsuccessful. If you continue to experience this issue, please contact support@pano.ai.',
  403: 'You do not have permission to complete this request. If you believe you are seeing this message in error, please contact support@pano.ai.',
  404: 'The requested resource was not found. If you believe you are seeing this message in error, please contact support@pano.ai.',
  409: 'There was a conflict with your request. Please try refreshing the page. If you believe you are seeing this message in error, please contact support@pano.ai.',
  410: 'The requested resource is no longer available. If you believe you are seeing this message in error, please contact support@pano.ai.',
  422: 'Your request could not be processed. Please try refreshing the page. If you continue to experience this error, please contact support@pano.ai.',
  429: 'You have exceeded the rate limit for this request. Please try again later. If you continue to experience this issue, please contact support@pano.ai.',
  500: 'There was an error processing your request. Please try again. If you continue to experience this error, please contact support@pano.ai.',
  502: 'The service is temporarily unavailable due to a bad gateway. Please try again later. If the issue persists, please contact support@pano.ai.',
  503: 'The service is temporarily unavailable. Please try again later. If the issue persists, please contact support@pano.ai.',
  504: 'The server took too long to respond. Please try again later. If the issue persists, please contact support@pano.ai.',
  FALLBACK: 'There was an error processing your request. If the issue persists, please contact support@pano.ai.',
};

const MESSAGES: MessagesType = {
  DEFAULT_ERROR: 'We failed to complete your requests. Please try again later.',
  DEFAULT_ERROR_LOGIN: 'Please login below.',
  MFA_CODE_EXPIRED: 'Your code has expired. Please send a new code.',
  MFA_CODE_INVALID: 'The verification code does not match.',
  MFA_CODE_FAILED_TO_SEND: 'There was an error sending a code. Please resend the code or try again later.',
  MARK_FIRE_DISABLED: 'Marking fire is disabled on this page',
  ACCESS_TOKEN_INVALID: 'The link you are using has expired. Please login or try a different link.',
  API_FORBIDDEN_ERROR: 'You do not have permission to complete the request.',
  API_FORMAT_ERROR: 'We are experiencing an error with your request, please try again in a few minutes.',
  API_GENERAL_ERROR:
    'There was an error with your request. Please refresh the page and try again, or email support@pano.ai',
  API_WEATHER_ERROR: 'There was an error fetching weather data. Please try again later, or email support@pano.ai',
  DATA_CONFLICTS: {
    GENERIC: [
      'The change you are trying to make conflicts with the changes made by another user.',
      'Please press OK to refresh the page so you can try your action again.',
    ],
    INCIDENT: [
      'This incident has been updated by another user.',
      'Please view the updated incident and try your action again.',
    ],
    MARK: ['This incident has been updated by another user.', 'Please view the updated incident.'],
    ACCOUNT: ['This update request was not successful.', 'Please reapply your changes and try again.'],
  },
  ACCOUNT_INSYNC: 'Your session has expired. You will be redirected to the login page shortly.',
  LOGOUT_FAILED: 'We failed to log you out. Please try again later.',
  NOTIFICATION_WARNINGS: {
    UPDATE_INCIDENT: [
      'Updating the incident status will send email and text notifications to everyone who is following this region.',
      'Are you sure you would like to proceed?',
    ],
    CREATE_INCIDENT: [
      'Creating an Alerted Incident or a Controlled Burn will send email and text notifications to everyone who is following this region.',
      'Are you sure you would like to proceed?',
    ],
  },
  INVITE_FAILED: 'We failed to send the invitation. Please try again.',
  INVITE_SENT: (email: string, org: string): string => `We sent an invite for ${org} to ${email}`,
  COMMUNITY_DUPLICATE_INVITE: (email: string, org: string): string =>
    `${email} has been added to ${org}. This user was already registered to another organization, so no registration email was sent.`,
  OZ_CONTROL_ERR_PAN: 'You have reached the maximum pan setting',
  OZ_CONTROL_ERR_TILT_MIN: 'You have reached the minimum tilt setting',
  OZ_CONTROL_ERR_TILT_MAX: 'You have reached the maximum tilt setting',
  OZ_CONTROL_ERR_ZOOM_MIN: 'You have reached the minimum zoom setting',
  OZ_CONTROL_ERR_ZOOM_MAX: 'You have reached the maximum zoom setting',
  OZ_UPDATE_ERR: 'Error updating Pan, Tilt, Zoom setting. Please try again.',
  OZ_QUEUE_IS_FULL: 'The station is busy executing another command.',
  OZ_CAMERA_MOVING: 'Camera Moving',
  DETECTION_IMAGE_FAILED_TO_LOAD: 'The detection image failed to load',
};

export const getNotificationWarningActionText = (type: string): string =>
  ({
    [IncidentNotificationType.UpdateIncident]: 'Update',
    [IncidentNotificationType.CreateIncident]: 'Create',
  }[type]);

export const getDataConflictTitle = (type: string): string =>
  ({
    generic: 'Data Conflict Error',
    incident: 'Incident Update Error',
    mark: 'Incident Mark Error',
    account: 'Update Account Error',
  }[type]);

export default MESSAGES;
