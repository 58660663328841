import React, { useEffect, useState } from 'react';
import SuspenseRipple from 'componentLibrary/SuspenseRipple';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface VariantProps<T> {
  /**
   * The child[ren] to render if the variant matches its condition and this variant is
   * either the first match, or the surrounding feature flag allows `all` matches.
   */
  children: React.ReactElement | React.ReactElement[];
  /**
   * The condition to test against the evaluated feature flag. This can be either a value,
   * which will be tested by equality, or a function that takes the flag value and returns a
   * `boolean` if it matches it. Undefined conditions evaluate to `true`.
   */
  condition?: T | ((value: T) => boolean);
}

type VariantPropsChild<T> = React.ReactElement<VariantProps<T>>;
type VariantPropsChildren<T> = VariantPropsChild<T> | VariantPropsChild<T>[];

interface FeatureFlagProps<T> {
  /** The key of the flag to evaluate. This flag's type must match `<T>`. */
  flagKey: string;
  /** The default value to return if the flag fails to evaluate. Its default varies by `<T>`. */
  defaultValue?: T;
  /** An indicator of whether all matching children should be rendered, or just the first one. Defaults to `false`. */
  all?: boolean;
  /** The child variants for this flag. These must match `<T>`. */
  children: VariantPropsChildren<T>;
  /** Whether we want to show the loading state */
  showLoader?: boolean;
}

/**
 * Match a child's condition against a value
 * @param child the child to check
 * @param value the value to check against this child's condition
 * @returns `true` on *any* of the following:
 *  - child's `condition` matches the `value` exactly
 *  - child's `condition` is a function and `condition(value)` is truthy
 *  - child's `condition` is undefined (unspecified)
 */
function matchChild<T>(child: VariantPropsChild<T>, value: T): boolean {
  const { condition } = child.props;

  return (
    condition === value ||
    (typeof condition === 'function' && (condition as (value: T) => boolean)(value)) ||
    condition === undefined
  );
}

/**
 * Get matching children
 * @param children the children to check for matches
 * @param value the value to check for match
 * @param all an indicator of whether to return *all* matching children, or just the first one
 * @returns a loading indicator if the `value` is undefined;
 *  otherwise the first, or all matching children, based on the `matchChild` semantics
 */
function getMatchingChildren<T>(
  children: VariantPropsChildren<T>,
  value: T,
  all = false,
  showLoader = false,
): React.ReactElement | React.ReactElement[] {
  if (value === undefined && showLoader) {
    return <SuspenseRipple isLoading={true} />;
  } else if (value === undefined && !showLoader) {
    return <></>;
  }
  const childrenArray = React.Children.toArray(children) as VariantPropsChild<T>[];

  return all
    ? childrenArray.filter((child) => matchChild(child, value))
    : childrenArray.find((child) => matchChild(child, value));
}

export function BooleanVariant({ children }: VariantProps<boolean>): React.ReactElement {
  return <>{children}</>;
}

export function BooleanFeatureFlag({
  flagKey,
  defaultValue = false,
  all = false,
  children,
  showLoader = false,
}: FeatureFlagProps<boolean>) {
  const [flagValue, setFlagValue] = useState(undefined);
  const { evaluateBooleanFlag } = useFeatureFlags();
  useEffect(() => {
    evaluateBooleanFlag(flagKey, defaultValue).then(setFlagValue);
  }, [evaluateBooleanFlag, flagKey, defaultValue]);

  return getMatchingChildren(children, flagValue, all, showLoader);
}

export function StringVariant({ children }: VariantProps<string>): React.ReactElement {
  return <>{children}</>;
}

export function StringFeatureFlag({ flagKey, defaultValue = null, all = false, children }: FeatureFlagProps<string>) {
  const [flagValue, setFlagValue] = useState(undefined);
  const { evaluateStringFlag } = useFeatureFlags();
  useEffect(() => {
    evaluateStringFlag(flagKey, defaultValue).then(setFlagValue);
  }, [evaluateStringFlag, flagKey, defaultValue]);

  return getMatchingChildren(children, flagValue, all);
}

export function NumberVariant({ children }: VariantProps<number>): React.ReactElement {
  return <>{children}</>;
}

export function NumberFeatureFlag({ flagKey, defaultValue = 0, all = false, children }: FeatureFlagProps<number>) {
  const [flagValue, setFlagValue] = useState(undefined);
  const { evaluateNumberFlag } = useFeatureFlags();
  useEffect(() => {
    evaluateNumberFlag(flagKey, defaultValue).then(setFlagValue);
  }, [evaluateNumberFlag, flagKey, defaultValue]);

  return getMatchingChildren(children, flagValue, all);
}
