import { PanoImagesData } from 'types';

import Comparators from './Comparators';
import RangeFilter from './RangeFilter';
import SortedMap from './SortedMap';

export default class CacheTier extends RangeFilter<PanoImagesData> {
  private _cache = new SortedMap<number, PanoImagesData>(Comparators.compareNumber);
  private _range: number[] = [0, 0];

  protected range(): number[] {
    return this._range;
  }

  protected async select(from: number, to: number): Promise<Map<number, PanoImagesData>> {
    console.info('CACHE -> [' + from + ', ' + to + ')');

    return this._cache.subMap(from, to);
  }

  // Interpret the range from the `data` itself.
  // Getting from `from` to `to` might get `data` without some frames.
  protected insert(from: number, to: number, data: Map<number, PanoImagesData>): void {
    console.info('CACHE <- [' + from + ', ' + to + ')', 'data', data);
    const dataKeys: number[] = [...data.keys()];
    const dataFrom: number = dataKeys[0];
    const dataTo: number = dataKeys[dataKeys.length - 1] + 1;

    this._range[0] = dataFrom < this._range[0] ? dataFrom : this._range[0];
    this._range[1] = this._range[1] < dataTo ? dataTo : this._range[1];

    this._cache.putAll(data);
  }
}
