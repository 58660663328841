import React, { useEffect } from 'react';
import { setupPublicApi } from 'config/api';
import { rscGlobalSnackbar } from 'data';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { GlobalSnackbarParams } from 'types';

import useInterval from '@use-it/interval';

const Heartbeat: React.FC = () => {
  const setGlobalSnackbar: SetterOrUpdater<GlobalSnackbarParams> = useSetRecoilState(rscGlobalSnackbar);

  // Check local account info every 5 seconds
  useInterval(async () => {
    // await authStore.syncAccount();
  }, 5000);

  useEffect(() => {
    setupPublicApi(setGlobalSnackbar);
  }, [setGlobalSnackbar]);

  return null;
};

export default React.memo(Heartbeat);
