import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  locationDisclaimer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  }),
  inline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stacked: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerContainer: {
    padding: 2,
    display: 'flex',
  },
  locationText: {
    textAlign: 'end',
  },
  icon: {
    height: '16px',
    width: '16px',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  tooltipHeader: (theme: Theme) => ({
    fontWeight: theme.typography.fontWeightSemiBold,
  }),
};

export default styles;
