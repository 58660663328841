import React from 'react';

import Typography from '@mui/material/Typography';

import styles from './TaskInfoItem.styles';

interface Props {
  /** The text for the bolded label */
  title: string;
  /** The value to show next to the bolded label */
  value: string | React.ReactElement;
}

/**
 * A component for each of the lines in the Task Info
 */
export const TaskInfoItem = ({ value, title }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <Typography>
      <Typography component='label' sx={styles['label']} htmlFor={`task-info-${title}-content`}>
        {`${title}: `}
      </Typography>
      <Typography component='span' id={`task-info-${title}-content`}>
        {value}
      </Typography>
    </Typography>
  );
};

export default TaskInfoItem;
