import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'searching-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },
  'searching-text-container': {
    marginBottom: 5,
  },
  'loading-indicator': {
    marginBottom: 5,
    '& img': {
      margin: 'auto',
    },
  },
};

export default styles;
