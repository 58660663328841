import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  layoutContainer: {
    flexGrow: 1,
  },
  alertContainer: {
    marginTop: 3,
  },
};

export default styles;
