import React from 'react';
import Alert from 'componentLibrary/Alert';
import BaseLayout from 'componentLibrary/Layouts/BaseLayout';
import Link from 'componentLibrary/Link';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './FailureToLoad.styles';

export const FailureToLoad = () => {
  return (
    <Box sx={styles.pageContainer}>
      <BaseLayout withHeader sx={styles.layoutContainer}>
        <Alert type='error' sx={styles.alertContent}>
          <Typography component='h1' variant='h4' gutterBottom>
            Pano 360 Error
          </Typography>
          <Typography>Something went wrong. Please clear your cache and refresh the page.</Typography>
          <Typography gutterBottom>
            If this issue persists please contact <Link href='mailto:support@pano.ai'>Pano Support</Link>.
          </Typography>
        </Alert>
      </BaseLayout>
    </Box>
  );
};

export default FailureToLoad;
