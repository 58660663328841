import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'task-actions-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'disposition-box': {
    marginBottom: 2,
  },
};

export default styles;
