import React, { useCallback, useState } from 'react';
import Alert from 'componentLibrary/Alert';
import Link from 'componentLibrary/Link';
import { rsaIsFullAuth, rspwIsSaveReminderActive } from 'data';
import useLogout from 'hooks/useLogout';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import styles from '../UserMenuItems.styles';

import { useAutoDismissLogoutWarning } from './PICLogoutButton.helpers';

const PICLogoutButton = ({ setProfileMenuAnchorEl }: { setProfileMenuAnchorEl: React.Dispatch<Element> }) => {
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const isLogoutDisabled = useRecoilValue(rspwIsSaveReminderActive);

  const [isOpen, setIsOpen] = useState(false);

  const logoutUser = useLogout({});
  const history = useHistory();

  const closeWarningModal = useCallback(() => {
    setIsOpen(false);
    setProfileMenuAnchorEl(null);
  }, [setProfileMenuAnchorEl]);

  useAutoDismissLogoutWarning(closeWarningModal);

  return (
    <>
      <MenuItem
        key='menu-login-logout'
        onClick={() => {
          if (isLogoutDisabled) {
            setIsOpen(true);
          } else if (isFullAuth) {
            setProfileMenuAnchorEl(null);
            logoutUser(null);
          } else {
            history.push('/login');
          }
        }}
        data-cy='menu-login-logout'
      >
        {isLogoutDisabled ? (
          <Link sx={styles['profile-popver-item']}>Logout</Link>
        ) : (
          <Link to='/#' sx={styles['profile-popver-item']}>
            Logout
          </Link>
        )}
      </MenuItem>
      <Dialog open={isOpen} onClose={closeWarningModal}>
        <DialogTitle>Logout Warning</DialogTitle>
        <DialogContent>
          <Alert type='error'>Please go inactive in order to log out.</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningModal} variant='contained' data-cy='pic-ok-button'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PICLogoutButton;
