import { useEffect } from 'react';
import {
  rsaFrozenUser,
  rsaIsFullAuth,
  rsaIsPendingMfaSetup,
  rsaIsRequiredToAgreeToTerms,
  rsaRedirectUrl,
  rsaSecuritySettingsChanged,
} from 'data';
import { useLocation } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * In the case a user needs to complete a task before accessing the application
 * this hook persists the target url, so we can redirect the user properly after authenticating.
 * example use cases include Authenticating, or needing to agree to Terms Of Service
 */
export const useSetRedirectUrl = () => {
  const isAuthenticated = useRecoilValue(rsaIsFullAuth);
  const isPendingMfaSetup = useRecoilValue(rsaIsPendingMfaSetup);
  const securitySettingsChanged = useRecoilValue(rsaSecuritySettingsChanged);
  const setRedirectUrl = useSetRecoilState(rsaRedirectUrl);
  const isRequiredToAgreeToTerms = useRecoilValue(rsaIsRequiredToAgreeToTerms);

  const frozenUser = useRecoilValue(rsaFrozenUser);

  const location = useLocation();

  useEffect(() => {
    if (
      (!isAuthenticated && !frozenUser && !isPendingMfaSetup && !securitySettingsChanged) ||
      (isRequiredToAgreeToTerms && location.pathname !== '/account/tos')
    ) {
      setRedirectUrl(location.pathname);
    }
  });
};
