import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'task-info-container': {
    marginBottom: 2,
  },
  label: {
    fontWeight: 'bold',
  },
  'task-info-image': {
    width: '100%',
  },
};

export default styles;
