import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { MapLayerConfig } from 'types';

export default class MapLayerConfigApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    const api = await authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * @description Fetches the map layer configs available to this user
   */
  async apiFetchMapLayerConfigs(): Promise<MapLayerConfig[]> {
    try {
      const { data } = await this.api.get(`/maplayerconfig`);

      return data as MapLayerConfig[];
    } catch (e) {
      console.error('[Pano] API Error MapLayerConfig', e);

      return [];
    }
  }

  /**
   * @description Fetches the map layer config by Id
   * - Used to set the signed cookie for that map layer
   * @returns the Time To Live, to know when to extend the cookie
   */
  async apiGetMapLayerSignedCookie(mapLayerConfigId: MapLayerConfig['id']) {
    try {
      const { headers } = await this.api.get(`/maplayerconfig/${mapLayerConfigId}`, { withCredentials: true });

      return headers['x-ttl'];
    } catch (e) {
      console.error('[Pano] API Error MapLayerConfig', e);

      return null;
    }
  }
}
