import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  loader: {
    width: '150px',
    display: 'block',
    margin: '200px auto',
  },
};

export default styles;
