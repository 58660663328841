import { useEffect, useRef, useState } from 'react';
import {
  rspwIsPicUserAvailableSynced,
  rstHasBeenRedirected,
  rstIsTaskAcknowledged,
  rstTaskExpirationTimeMs,
  rstTaskRedirectUrl,
} from 'data';
import usePageTitleWithMessage from 'hooks/usePageTitleUpdate/usePageTitleWithMessage';
import useTasks from 'hooks/useTasks';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useInterval from '@use-it/interval';

/**
 * Updates the page title for a task that is unacknowledged
 * - unacknowledged means the page is not visible
 */
export const useUnacknowledgedTitle = () => {
  const [isTaskAcknowledged, setIsTaskAcknowledged] = useRecoilState(rstIsTaskAcknowledged);
  const [titleMessage, setTitleMessage] = useState('');

  useEffect(() => {
    if (!document.hidden) {
      setIsTaskAcknowledged(true);
    }
    setTitleMessage(!isTaskAcknowledged ? 'New Task Available' : '');
  }, [isTaskAcknowledged, setIsTaskAcknowledged]);

  usePageTitleWithMessage(titleMessage);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIsTaskAcknowledged(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setIsTaskAcknowledged]);
};
/**
 * When a user receives a new task, redirect them to the full Pano at the appropriate bearing
 */
export const useRedirectToFullPano = () => {
  const history = useHistory();
  const [hasBeenRedirected, setHasBeenRedirected] = useRecoilState(rstHasBeenRedirected);

  const redirectURL = useRecoilValue(rstTaskRedirectUrl);

  useEffect(() => {
    if (!hasBeenRedirected && redirectURL) {
      history.push(redirectURL);
      setHasBeenRedirected(true);
    }
  }, [hasBeenRedirected, history, redirectURL, setHasBeenRedirected]);
};

/**
 * If a user's task is expired, clear the task and set them to unavailable
 * - Only runs the expired checks once the task could be expired
 */
export const useSetToInactiveIfExpired = () => {
  const expirationTime = useRecoilValue(rstTaskExpirationTimeMs);
  const setIsPicUserAvailable = useSetRecoilState(rspwIsPicUserAvailableSynced);

  const { clearActiveTask } = useTasks();

  const now = new Date();

  let intervalToCheck = expirationTime - now.getTime();
  intervalToCheck = intervalToCheck < 0 ? 0 : intervalToCheck;

  useInterval(() => {
    const now = new Date();
    if (expirationTime < now.getTime()) {
      clearActiveTask();
      setIsPicUserAvailable(false);
    }
  }, intervalToCheck);
};

export const TASK_EXPIRATION_BUFFER = 10_000;

/**
 * Extends the expiration time for the current task
 * - Extends it 10 seconds before it is about to expire.
 * - If a user opens a tab and there are less than 10 seconds til expiration, it will extend immediately
 * - Has a locking mechanism to prevent multiple extensions
 * - Loops by rerunning the hook each time it gets a new expirationTime
 */
export const useExtendTaskExpiration = () => {
  const expirationTime = useRecoilValue(rstTaskExpirationTimeMs);
  const { extendActiveTask } = useTasks();
  const [isExtending, setIsExtending] = useState(false);
  const timeoutRef = useRef(null);
  const [isAbleToExtendTask, setIsAbleToExtendTask] = useState(true);

  useEffect(() => {
    const extendTask = async () => {
      if (!isExtending) {
        setIsExtending(true);
        try {
          const res = await extendActiveTask();

          setIsAbleToExtendTask(res);
        } catch (err) {
          console.error(err);
        }
        setIsExtending(false);
      }
    };

    /**
     * If we want to keep Schedules the extension of the task to occurr either:
     * 1. Now
     * 2. When the task is set to expire plus a buffer
     */
    const scheduleTaskExtension = () => {
      const now = new Date();
      const intervalToExtendTask = expirationTime - (now.getTime() + TASK_EXPIRATION_BUFFER);

      if (!isAbleToExtendTask) {
        return;
      } else if (intervalToExtendTask <= 0) {
        extendTask();
      } else {
        timeoutRef.current = setTimeout(extendTask, intervalToExtendTask);
      }
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    scheduleTaskExtension();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [expirationTime, extendActiveTask, isAbleToExtendTask, isExtending]);
};
